<template>
    <y-loading
        :active="$wait.is('loading-node-details')"
        height="100px"
    >
        <div
            v-if="content"
            class="content"
        >
            <y-order-edition-diff :content="content" />
        </div>
    </y-loading>
</template>

<script>
    import YOrderEditionDiff from '@/modules/cart/components/details/OrderEditionDiff';

    export default {

        name: 'PreOrderEditedNodeModal',

        components: {
            YOrderEditionDiff,
        },

        props: {
            content: Array,
        },

    };

</script>
