<template>
    <y-panel :title="$t('cart.panel.comment.title')">
        <!-- Comment -->
        <form
            id="comment-form"
            @submit.prevent="save"
        >
            <y-form-field
                id="comment"
                v-model="model.text"
                type="textarea"
                name="comment"
                no-label
            />

            <div class="d-f align-items-center">
                <y-form-field
                    id="is_private"
                    v-model="model.is_private"
                    type="checkbox"
                    class="mb0"
                    name="is_private"
                />
                <y-button
                    color="blue"
                    loading-on="saving-setting"
                    form="comment-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </form>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {
        name: 'CommentPanel',

        components: {
            YFormField,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
            };
        },

        methods: {
            /**
             * Save Comment
             */
            save() {
                const params = {
                    ...this.model,
                    id: this.$route.params.id,
                };
                return this.$services.Cart.orderComment(params).then(() => {
                    this.$toast.success(this.$t('cart.panel.comment.message.success'));
                    this.$emit('done');
                }).catch((error) => {
                    this.handleError(error, this.$t('cart.panel.comment.message.error'));
                });
            },
        },
    };

</script>
