<template>
    <y-modal
        ref="Modal"
        class="inventory-modal"
        forced
    >
        <div slot="header">
            {{ title }}
        </div>

        <div
            v-if="status"
            slot="body"
        >
            <y-form-field
                v-model="reason"
                type="textarea"
                name="reason"
                :validation="status.value === 3 ? 'required' : ''"
            />

            <div>
                <p>{{ $t('cart.claim.message.change_status.prompt', [status.label]) }}</p>
            </div>
        </div>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="green"
                    loading-on="change-status-form"
                    @click.prevent.native="save"
                >
                    {{ $t('cart.actions.change_status') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal } from '@deps';
    import YFormField from '@deps/form/FormField';
    
    export default {
        name: 'ChangeClaimStatusModal',

        components: {
            YModal,
            YFormField,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                reason: null,
                status: null,
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.$t('cart.claim.change_status');
            },
        },

        methods: {
            /**
             * Open modal
             *
             * @param status
             */
            open(status) {
                this.$set(this, 'status', status);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('cancel');
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.reason = null;
            },

            /**
             * Save form
             */
            save() {
                this.$wait.start('change-status-form');
                const params = {
                    id    : this.$route.params.id,
                    status: this.status.value,
                    reason: this.reason,
                };
                this.$services.Cart.claimStatusChange(params).then(() => {
                    this.$emit('done');
                    this.$toast.success(this.$t('cart.claim.message.change_status.success'));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => {
                    this.$wait.end('change-status-form');
                });
            },
        },
    };
</script>
