<template>
    <div class="claim-form__wrapper">
        <table class="table bordered">
            <thead>
                <tr>
                    <th />
                    <th>{{ $t('cart.table.ware') }}</th>
                    <th>{{ $t('cart.table.count') }}</th>
                    <th>{{ $t('cart.table.claim.count_on') }}</th>
                    <th>{{ $t('cart.table.claim.raised_at') }}</th>
                    <th>{{ $t('cart.table.claim.reason') }}</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="claim && claim.claim_editor && claim.claim_editor.length">
                    <tr
                        v-for="(item, index) in claim.claim_editor"
                        :key="`ci-${index}`"
                    >
                        <template v-if="!isEdit || item">
                            <td class="col-min">
                                <y-form-field
                                    v-model="model.claims[index].active"
                                    type="checkbox"
                                    class="mb0"
                                    name="active"
                                    no-label
                                />
                            </td>
                            <td>
                                {{ item.title }}
                                <div class="mt10">
                                    <span
                                        v-if="item.claimable"
                                        class="label green"
                                    >
                                        {{ $t('cart.table.claim.ok') }}
                                    </span>
                                    <span
                                        v-else
                                        class="label red"
                                    >
                                        {{ $t('cart.table.claim.not_ok') }}
                                    </span>
                                </div>
                            </td>
                            <td>{{ item.count | digits }}</td>
                            <td>
                                <y-form-field
                                    v-model="model.claims[index].claimed_count"
                                    type="number"
                                    class="mb0"
                                    no-label
                                    size="sm"
                                />
                            </td>
                            <td>{{ claim.effected_at | date('YYYY/MM/DD', $i18n.locale) }}</td>
                            <td>
                                <y-form-field
                                    v-model="model.claims[index].claimed_reason"
                                    type="textarea"
                                    rows="2"
                                    class="mb0"
                                    no-label
                                    size="sm"
                                />
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="100%">
                            <y-form-field
                                v-model="model.comment"
                                type="textarea"
                                rows="2"
                                name="note"
                                size="sm"
                            />
                            <y-form-field
                                v-model="model.is_private"
                                type="checkbox"
                                class="mb0"
                                name="is_private"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td
                            colspan="100%"
                            class="ta-l claim-form__actions"
                        >
                            <y-button @click.prevent="$emit('reset')">
                                {{ isEdit ? $t('button.back') : $t('button.cancel') }}
                            </y-button>
                            <y-button
                                color="blue"
                                form="claim-form"
                                loading-on="saving-claim"
                            >
                                {{ $t('button.save') }}
                            </y-button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YFormField } from '@deps';

    export default {
        name: 'ClaimEditor',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            claim: [Object, Array],
            cart : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
            };
        },

        computed: {
            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.claim) {
                this.fetchClaim();
            }
        },

        methods: {
            /**
             * Fetch Claim
             */
            fetchClaim() {
                if (this.claim && !this.isEdit) {
                    this.model = {
                        order_id  : this.claim.id,
                        comment   : '',
                        is_private: 0,
                    };
                    this.$set(this.model, 'claims', this.claim.claim_editor.map((item) => ({
                        active        : 1,
                        title         : item.title,
                        item_id       : item.id,
                        claimed_count : 0,
                        claimed_reason: '',
                    })));
                }
                if (this.claim && this.isEdit) {
                    this.model = {
                        order_id  : this.claim.order_id,
                        comment   : '',
                        is_private: 0,
                    };
                    this.$set(this.model, 'claims', this.claim.claim_editor.map((item) => ({
                        active        : item ? item.active : 0,
                        title         : item ? item.title : '',
                        item_id       : item ? item.id : 0,
                        count         : item ? item.count : 0,
                        claimed_count : item ? item.claimed_count : 0,
                        claimed_reason: item ? item.claimed_reason : '',
                    })));
                }
            },

            /**
             * Check if object is empty
             *
             * @param obj
             */
            isEmpty(obj) { // eslint-disable-line consistent-return
                if ((typeof obj === 'object') && (obj !== null)) {
                    return (Object.entries(obj).length === 0 && obj.constructor === Object);
                }
            },
        },
    };

</script>
