<template>
    <div class="cart-bill">
        <y-page-head
            :title="pageHeadTitle"
            icon="md-cart-arrow-right"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    key="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    class="invoice-button"
                    color="yellow"
                    @click="printSection"
                >
                    {{ $t('cart.actions.print') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 300px )"
        >
            <div class="cart-bill__wrapper">
                <y-panel class="invoice-wrapper">
                    <div
                        v-if="bill"
                        id="bill"
                        ref="bill"
                        class="invoice"
                    >
                        <!-- Invoice Title -->
                        <div class="row align-items-center invoice-row invoice-header">
                            <div class="col-print-3 resized col-8 col-md-9">
                                <h3 class="invoice-title">
                                    {{ $t('cart.bill.title') }}
                                </h3>
                            </div>
                            <div class="col-print-1 resized col-4 col-md-2">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="invoice-block">
                                            <span class="invoice-block-key">{{ $t('invoice.number') }}</span>
                                            <span class="invoice-block-value">{{ bill.tracking_number | digits }}</span>
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <p class="invoice-block">
                                            <span class="invoice-block-key">{{ $t('invoice.date') }}</span>
                                            <span
                                                v-if="bill.effected_at"
                                                class="invoice-block-value"
                                            >{{ bill.effected_at | date('YYYY/MM/DD', $i18n.locale) | digits }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row no-gutters bill-info d-f align-items-start">
                            <div class="col-print-2 col-sm-6">
                                <!-- Seller Details -->
                                <y-bill-seller-info
                                    v-if="seller"
                                    :title="$t('invoice.seller_title')"
                                    :item="seller"
                                />
                            </div>
                            <div class="col-print-2 col-sm-6">
                                <!-- Buyer Details -->
                                <y-bill-buyer-info
                                    v-if="buyer"
                                    :title="$t('invoice.buyer_title')"
                                    :item="buyer"
                                    :address="bill.address"
                                    :customer="bill.customer"
                                />
                            </div>
                        </div>

                        <!-- Items Details -->
                        <div class="row invoice-row">
                            <div class="col-12 invoice-subtitle-bg">
                                <h3 class="invoice-subtitle">
                                    {{ $t('invoice.wares_title') }}
                                </h3>
                            </div>
                            <div class="col-12 invoice-table">
                                <div class="invoice-table-container">
                                    <div class="table-wrapper">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        v-for="column in items_table"
                                                        :key="column.id"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ column.title }}
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody v-if="items && items.length">
                                                <tr
                                                    v-for="(row, index) in items"
                                                    :key="row.id"
                                                >
                                                    <td class="invoice-block-value">
                                                        {{ (index + 1) | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.ware_title | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.count }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.order_price.price }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.order_price.discount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.order_price.tax }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: (((row.order_price.price - row.order_price.discount) + row.order_price.tax) * row.count ) }"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="10">
                                                        {{ $t('invoices.empty_wares') }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td
                                                        colspan="3"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ `${$t('invoice.product.total')} (${bill.currency_title})` }}
                                                    </td>
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: sum('price') }"
                                                        />
                                                    </td>
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: sum('discount') }"
                                                        />
                                                    </td>
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: sum('tax') }"
                                                        />
                                                    </td>
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: sum('total') }"
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        colspan="3"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ `${$t('invoice.product.total_coupon_discount')} (${bill.currency_title})` }}
                                                    </td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: bill.coupon_discount }"
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        colspan="3"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ `${$t('invoice.product.shipping')} (${bill.currency_title})` }}
                                                    </td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: bill.delivery_price }"
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        colspan="3"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ `${$t('invoice.product.payable')} (${bill.currency_title})` }}
                                                    </td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: bill.payable_amount }"
                                                        />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Transactions Details -->
                        <div class="row invoice-row">
                            <div class="col-12 invoice-subtitle-bg">
                                <h3 class="invoice-subtitle">
                                    {{ $t('invoice.transactions_title') }}
                                </h3>
                            </div>
                            <div class="col-12 invoice-table">
                                <div class="invoice-table-container">
                                    <div class="table-wrapper">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        v-for="column in transactions_table"
                                                        :key="column.id"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ column.title }}
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody v-if="transactions && transactions.length">
                                                <tr
                                                    v-for="(row, index) in transactions"
                                                    :key="row.id"
                                                >
                                                    <td class="invoice-block-value">
                                                        {{ (index + 1) | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.effected_at ? (dateHelper(row.effected_at)) : `-` }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.path_title | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.amount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.type_label| digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.is_online ? $t('invoice.transactions.online') : $t('invoice.transactions.offline') }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.status_label }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.bank_track_no ? (row.bank_track_no | digits) : `-` }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="10">
                                                        {{ $t('invoice.empty_transaction') }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </y-panel>
            </div>
        </y-loading>
    </div>
</template>

<script>

    import { date } from '@nodes/helpers/date';
    import PageMixin from '@/mixins/Page';
    import Num2persian from '@/modules/cart/mixins/digitsToPersianWords';
    import YFormAmount from '@deps/form/elements/Amount';
    import YBillSellerInfo from '@/modules/cart/components/Bill/SellerInfo';
    import YBillBuyerInfo from '@/modules/cart/components/Bill/BuyerInfo';

    export default {
        name: 'CartBill',

        components: {
            YFormAmount,
            YBillSellerInfo,
            YBillBuyerInfo,
        },

        mixins: [
            PageMixin,
            Num2persian,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('cart', this.$t('breadcrumbs.cart.cart')),
                this.$bc('cart-orders-list', this.$t('breadcrumbs.cart.order.title')),
                this.$bc(this.$t('breadcrumbs.cart.bill.title')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('cart.bill.title'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                id          : this.$route.params.id,
                seller      : [],
                buyer       : [],
                bill        : [],
                transactions: [],
                items       : [],

                items_table: [
                    {
                        title: this.$t('invoice.wares.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('invoice.wares.title'),
                        id   : 'title',
                    },
                    {
                        title: this.$t('invoice.wares.count'),
                        id   : 'count',
                    },
                    {
                        title: this.$t('invoice.wares.price'),
                        id   : 'price',
                    },
                    {
                        title: this.$t('invoice.wares.discount'),
                        id   : 'discount',
                    },
                    {
                        title: this.$t('invoice.wares.tax'),
                        id   : 'tax',
                    },
                    {
                        title: this.$t('invoice.wares.total'),
                        id   : 'total',
                    },
                ],

                transactions_table: [
                    {
                        title: this.$t('invoice.transactions.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('invoice.transactions.date'),
                        id   : 'date',
                    },
                    {
                        title: this.$t('invoice.transactions.path'),
                        id   : 'path',
                    },
                    {
                        title: this.$t('invoice.transactions.amount'),
                        id   : 'amount',
                    },
                    {
                        title: this.$t('invoice.transactions.type'),
                        id   : 'type',
                    },
                    {
                        title: this.$t('invoice.transactions.method'),
                        id   : 'method',
                    },
                    {
                        title: this.$t('invoice.transactions.status'),
                        id   : 'status',
                    },
                    {
                        title: this.$t('invoice.transactions.tracking_number'),
                        id   : 'tracking_number',
                    },
                ],
            };
        },

        computed: {
            /**
             * Return Page title
             */
            pageHeadTitle() {
                return this.$t('cart.bill.title');
            },
        },

        methods: {

            /**
             * fetch data
             */
            fetch() {
                const params = {
                    id       : this.$route.params.id,
                    including: ['transactions', 'general_seller', 'general_buyer', 'currency_title', 'effected_at',
                                'address', 'customer', 'paid_amount', 'payable_amount', 'coupon_discount', 'delivery_price', 'title', 'tracking_number', 'editor_items:items'],
                };
                return this.$services.Cart.orderDetails(params).then((response) => {
                    this.bill = response.data.results;
                    this.seller = response.data.results.general_seller;
                    this.buyer = response.data.results.general_buyer;
                    this.items = response.data.results.items;
                    this.transactions = response.data.results.transactions;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Sum of the item
             *
             * @param item
             */
            sum(item) {
                let sum = 0;
                if (item !== 'total') {
                    this.items.forEach((i) => {
                        sum += (i.order_price[item] * i.count);
                    });
                }
                if (item === 'total') {
                    this.items.forEach((i) => {
                        sum += (((i.order_price.price - i.order_price.discount) + i.order_price.tax) * i.count);
                    });
                }
                return sum;
            },

            /**
             * Date helper for using in inline condition
             *
             * @param value
             */
            dateHelper(value) {
                return date(value, 'YYYY/MM/DD', this.$i18n.locale);
            },

            /**
             * Print Section
             */
            printSection() {
                const dir = this.$i18n.locale === 'fa' ? 'rtl' : 'ltr';
                const body = document.getElementById('bill').innerHTML;
                const WinPrint = window.open('', '_blank', 'top=0,right=0,width=1600,height=794,toolbar=0,scrollbars=0,status=0,fullscreen=1');
                WinPrint.document.write(`<!DOCTYPE html>
                <html dir="${dir}" lang="${this.$i18n.locale}">
                  <head>
                    <style type="text/css">${this.$config('print.typo')}</style>
                    <style type="text/css">${this.$config('print.col')}</style>
                    <style type="text/css">${this.$config('print.table')}</style>
                    <style type="text/css">${this.$config('print.invoice')}</style>
                    <title>${document.title}</title>
                  </head>
                  <body class="print">
                    ${body}
                  </body>
                </html>`);
                setTimeout(() => {
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            },
        },
    };

</script>
