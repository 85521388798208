<template>
    <div class="row invoice-row">
        <div class="col-12 invoice-subtitle-bg">
            <h3 class="invoice-subtitle">
                {{ title }}
            </h3>
        </div>
        <div
            v-if="item && address"
            class="col-12"
        >
            <div class="row invoice-block-row">
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.name') }}</span>
                        <span class="invoice-block-value">
                            {{ item.name ? item.name : `-` }}
                        </span>
                    </p>
                </div>
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.code_melli') }}</span>
                        <span class="invoice-block-value">
                            {{ (item.code_melli ? item.code_melli : `-`) | digits }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="row invoice-block-row">
                <div class="col-print-4 col-12">
                    <div class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.address') }}</span>
                        <div
                            v-if="address.address || address.province_name || address.city_name"
                            class="invoice-block-value"
                        >
                            {{ address.province_name ? $t('invoice.address_dynamic', [address.province_name]) : `` }}
                            {{ address.city_name ? $t('invoice.address_dynamic', [address.city_name]) : `` }}
                            {{ address.address | digits }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row invoice-block-row">
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.zipcode') }}</span>
                        <span class="invoice-block-value">{{ (address.postal_code ? address.postal_code : `-`) | digits }}</span>
                    </p>
                </div>
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.phone') }}</span>
                        <span class="invoice-block-value">
                            {{ (address.tel ? address.tel : `-`) | digits }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'YBuyerInfo',

        props: {
            title   : String,
            item    : [Array, Object],
            address : [Object, Array],
            customer: [Object, Array],
        },
    };

</script>
