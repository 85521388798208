<template>
    <nav id="cart-mega-menu">
        <y-loading
            :active="!items"
            height="52px"
        >
            <div id="mega-menu-content">
                <div class="cart-title">
                    {{ $t('cart.megamenu.title', [title]) }}
                </div>
                <ul
                    v-if="items"
                    class="level-0"
                >
                    <template v-for="(item, index) in items">
                        <li
                            :key="index"
                            class="dropdown"
                        >
                            <a
                                href="#"
                                @click.prevent
                            >
                                {{ item.title }}
                                <i class="icon md-chevron-down" />
                            </a>
                            <div class="menu">
                                <template v-for="(child, i) in item.children">
                                    <hr
                                        v-if="child.divider"
                                        :key="i"
                                        class="gray-lightest"
                                    >
                                    <router-link
                                        v-else
                                        :key="`cart-menu-${i}`"
                                        :to="cartRoute(child)"
                                    >
                                        <i
                                            class="icon"
                                            :class="child.icon"
                                        />
                                        {{ child.title }}
                                    </router-link>
                                </template>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </y-loading>
    </nav>
</template>

<script>

    import { YLoading } from '@deps';

    export default {

        name: 'YMegaMenu',

        components: {
            YLoading,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                title: null,
                items: null,
            };
        },

        watch: {
            '$route.params.slug': { // eslint-disable-line
                handler: function (newValue, oldValue) { // eslint-disable-line
                    if (newValue !== oldValue) {
                        this.fetch();
                    }
                },

                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch menu items
             */
            fetch() {
                this.items = null;
                const params = {
                    slug: this.$route.params.slug,
                };
                this.$services.Cart.panelMenu(params).then((response) => {
                    this.items = response.data.results;
                    this.title = this.get(response.data.metadata, 'content_type.title');
                }).catch((error) => {
                    this.handleError(error);
                    this.$emit('error');
                });
            },

            /**
             * Create cart item link
             *
             * @param child
             */
            cartRoute(child) {
                let route = {
                    name : child.route,
                    query: child.query,
                };
                if (child.params) {
                    route = {
                        name  : child.route,
                        query : child.query,
                        params: child.params,
                    };
                }
                return route;
            },

            /**
             * Check if should show
             *
             * @param key
             */
            shouldShowParent(key) {
                return this.items.some((el) => el.startsWith(key));
            },

            /**
             * Check if should show
             *
             * @param key
             */
            shouldShowChild(key) {
                return this.items.includes(key);
            },
        },

    };
</script>
