<template>
    <div>
        <y-page-head
            :title="pageHeadTitle"
            icon="md-file-document"
        />

        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <!-- Search Panel -->
                <y-loading
                    :active="$wait.is('loading-claim-editor')"
                    height="calc( 400px )"
                >
                    <div
                        v-if="!claimEditor && !isEdit"
                        class="row"
                    >
                        <div class="col-sm-8 col-center">
                            <y-claim-search
                                ref="claimSearch"
                                @create="createClaim"
                            />
                        </div>
                    </div>

                    <!-- Claim Editor -->
                    <div
                        v-if="claimEditor"
                        id="cart-details"
                        class="row"
                    >
                        <div class="col-sm-8">
                            <form
                                id="claim-form"
                                class="panel claim-form"
                                @submit.prevent="submit(save)"
                            >
                                <y-claim-editor
                                    v-model="model"
                                    :claim="claim"
                                    @reset="resetEditor"
                                />
                            </form>
                        </div>
                        <div class="col-sm-4">
                            <y-details-panel
                                v-if="details"
                                :cart="details"
                            />
                        </div>
                    </div>
                </y-loading>
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YDetailsPanel from '@/modules/cart/components/details/DetailsPanel';
    import YClaimEditor from '@/modules/cart/components/claims/Editor';
    import YClaimSearch from '@/modules/cart/components/claims/Search';

    export default {

        name: 'CartClaimCreate',

        components: {
            YDetailsPanel,
            YClaimEditor,
            YClaimSearch,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.isEdit ? this.get(this.details, 'title', '') : this.$t('cart.claim.create'),
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('cart', this.$t('breadcrumbs.cart.cart')),
                this.$bc(this.$t('breadcrumbs.cart.claim.title')),
                this.$bc(this.$route.params.id ? this.$t('breadcrumbs.cart.claim.edit') : this.$t('breadcrumbs.cart.claim.create')),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                claim   : null,
                details : null,
                model   : {},
                statuses: [],
                status  : null,

                // Control Visibility
                claimEditor: false,
            };
        },

        computed: {

            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Check if redirect from cart details page
             */
            isCartRedirect() {
                return !!this.$route.params.order;
            },

            /**
             * Page title
             */
            pageHeadTitle() {
                return this.isEdit
                    ? this.get(this.details, 'title', '')
                    : this.$t('breadcrumbs.cart.claim.create');
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.isEdit) {
                this.fetchClaim(this.$route.params.id);
            }
        },

        methods: {
            /**
             * Create new Claim
             *
             * @param orderId
             */
            createClaim(orderId) {
                this.$wait.start('loading-claim-editor');
                this.fetchCreateClaim(orderId);
            },

            /**
             * Fetch Claim for edit claim
             *
             * @param id
             */
            fetchClaim(id) {
                this.$wait.start('loading-page');
                return this.$services.Cart.claimFetch({ id }).then((response) => {
                    this.claim = response.data.results;
                    this.statuses = response.data.metadata.statuses;
                    this.fetchOrderDetails(this.claim.order_id);
                    if (this.claim) {
                        this.$set(this, 'status', this.claim.status);
                    }
                    this.claimEditor = true;
                }).catch((error) => {
                    this.handleError(error);
                    this.$router.push({
                        name: 'cart-claims-list',
                    });
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Fetch Order and claim info for create claim
             *
             * @param id
             */
            fetchCreateClaim(id) {
                this.claim = null;
                this.model = null;

                const params = {
                    id,
                    including: ['claim_editor', 'effected_at'],
                };
                return this.$services.Cart.claimOrderGet(params).then((response) => {
                    this.claim = response.data.results;
                    this.fetchOrderDetails(id);
                    this.claimEditor = true;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-claim-editor');
                });
            },

            /**
             * Fetch order details
             *
             * @param id
             */
            fetchOrderDetails(id) {
                this.details = null;
                return this.$services.Cart.orderDetails({ id }).then((response) => {
                    this.details = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save claim
             */
            save() {
                this.$wait.start('saving-claim');
                const params = {
                    ...this.model,
                    status: this.status,
                };
                if (!this.isEdit) {
                    params.order_id = this.claim.id;
                }
                if (this.isEdit) {
                    params.order_id = this.claim.order_id;
                    params.id = this.$route.params.id;
                }
                return this.$services.Cart.claimSave(params).then(() => {
                    if (!this.isEdit) {
                        this.$router.push({
                            name: 'cart-claims-list',
                        });
                    }
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('cart.claim.name') }));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('saving-claim');
                });
            },

            /**
             * Reset Editor
             */
            resetEditor() {
                if (this.isEdit) {
                    this.$router.push({
                        name: 'cart-claims-list',
                    });
                } else {
                    this.claimEditor = false;
                    this.$refs.claimSearch.resetSearch();
                }
            },

        },

    };

</script>
