<template>
    <y-modal
        ref="Modal"
        :title="$t('cart.panel.user.create')"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('loading-post')"
                height="200px"
            >
                <form
                    id="faq-form"
                    @submit.prevent="submit(save)"
                >
                    <y-form
                        v-model="model"
                        :params="form"
                    />
                </form>
            </y-loading>
        </div>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    form="faq-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YForm } from '@deps';

    export default {
        name: 'CreateUserModal',

        components: {
            YModal,
            YForm,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
                form : null,
            };
        },

        methods: {

            /**
             * Save form
             */
            fetch() {
                this.$wait.start('loading-post');
                const params = {
                    role: 'customer',
                };
                return this.$services.Person.usersPanelSaveForm(params).then((response) => {
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                }).catch((error) => {
                    this.close();
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-post'));
            },

            /**
             * Save form
             */
            save() {
                return this.$services.Person.usersPanelSave(this.model).then((response) => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('cart.panel.address.name') }));
                    this.$emit('done', {
                        id       : response.data.metadata.hashid,
                        name_full: `${this.model.name_first} ${this.model.name_last}`,
                    });
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal
             */
            open() {
                this.fetch();
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.form = null;
                this.model = {};
            },

        },
    };
</script>
