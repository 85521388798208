/*-----------------------------------------------------------------
- Status Label Mixin
-----------------------------------------------------------------*/

export default {
    
    name: 'StatusMixin',
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            statusClass: '',
        };
    },
    
    /**
     * @inheritDoc
     */
    created() {
        this.setStatusClass();
    },
    
    methods: {
        /**
         * Set status Class if value is status
         * Styles are defined in _cart.scss
         *
         * @param status
         */
        setStatusClass(status) {
            const code = status || this.status;
            const prefix = 'label status';
            const nd = 'not-defined';
            // TODO: add `constructed` status code
            const statusOptions = {
                0  : 'draft',
                100: 'pre-order-draft',
                101: 'pre-order-create',
                111: 'pre-order-confirmed',
                181: 'pre-order-expired',
                191: 'pre-order-reject',
                301: 'order-raised',
                311: 'order-confirmed',
                321: 'order-prepared',
                331: 'order-sent',
                341: 'order-delivered',
                391: 'order-rejected',
                501: 'claim-raised',
                511: 'claim-confirmed',
                531: 'sent-back',
                541: 'delivered-back',
                591: 'claim-rejected',
                901: 'closed',
            };
            if (code in statusOptions) {
                this.statusClass = `${prefix}-${statusOptions[code]}`;
            } else {
                this.statusClass = `${prefix}-${nd}`;
            }
        },
    },
    
};
