<template>
    <y-modal ref="Modal">
        <span slot="header">{{ promptTitle }}</span>

        <div slot="body">
            <p class="mb0">
                {{ promptCaption }}
            </p>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click.prevent.native="$refs.Modal.close()"
            >
                {{ $t('button.cancel') }}
            </y-button>
            <y-button
                :loading="$wait.is(promptLoading)"
                color="blue"
                @click.prevent.native="promptAction"
            >
                {{ $t('button.confirm') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal } from '@deps';

    export default {
        name: 'OrderPromptModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                promptCaption: null,
                promptAction : null,
                promptTitle  : null,
                promptLoading: null,
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param params
             * @typedef {{promptCaption: string, promptAction: Function, promptTitle: string, promptLoading: string}} params
             */
            open(params) {
                this.promptCaption = params.promptCaption;
                this.promptAction = params.promptAction;
                this.promptTitle = params.promptTitle;
                this.promptLoading = params.promptLoading;
                
                this.$refs.Modal.open();
            },
            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },
        },

    };

</script>
