<template>
    <div>
        <y-page-head
            :title="pageHeadTitle"
            :desc="claimDate"
            icon="md-comment-search-outline"
        >
            <template
                v-if="claim && statuses"
                slot="actions"
            >
                <y-button @click.prevent.native="$refs.PaymentProcessModal.open(claim.id)">
                    {{ $t('cart.actions.payment') }}
                </y-button>

                <y-button @click.prevent.native="$refs.FinalizationPromptModal.open(claim.id)">
                    {{ $t('cart.actions.claim_close') }}
                </y-button>

                <y-dropdown v-if="statuses && statuses.length > 0">
                    <template slot="toggle">
                        <y-button>{{ $t('cart.actions.change_status') }}</y-button>
                    </template>

                    <span
                        v-for="(item, index) in statuses"
                        :key="index"
                        class="cart-change-status"
                        :class="{'active': index === status}"
                        @click="changeStatusModal(item)"
                    >
                        {{ item.label }}
                    </span>
                </y-dropdown>
            </template>
        </y-page-head>


        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <div class="row">
                    <div class="col-sm-8">
                        <y-claim-process
                            v-if="claim"
                            :claim="claim"
                            :confirmed="confirmed"
                            @done="reFetch"
                        />
                    </div>
                    <div class="col-sm-4">
                        <y-loading
                            :active="$wait.is('loading-order-details')"
                            height="calc( 400px )"
                        >
                            <y-details-panel
                                v-if="details"
                                :cart="details"
                            />
                        </y-loading>
                    </div>
                </div>
            </main>
        </y-loading>

        <y-change-claim-status-modal
            ref="ChangeClaimStatusModal"
            @done="reFetch"
            @cancel="cancelStatus"
        />

        <y-payment-process-modal
            ref="PaymentProcessModal"
            @done="reFetch"
        />

        <y-finalization-prompt-modal
            ref="FinalizationPromptModal"
            @done="reFetch"
        />
    </div>
</template>

<script>

    import { date } from '@nodes/helpers/date';
    import PageMixin from '@/mixins/Page';
    import { YDropdown } from '@deps';
    import YDetailsPanel from '@/modules/cart/components/details/DetailsPanel';
    import YClaimProcess from '@/modules/cart/components/claims/Process';
    import YChangeClaimStatusModal from '@/modules/cart/components/claims/modals/ChangeClaimStatus';
    import YPaymentProcessModal from '@/modules/cart/components/claims/modals/PaymentProcess';
    import YFinalizationPromptModal from '@/modules/cart/components/claims/modals/FinalizationPrompt';

    export default {
        name: 'CartClaimProcess',

        components: {
            YDropdown,
            YDetailsPanel,
            YClaimProcess,
            YChangeClaimStatusModal,
            YPaymentProcessModal,
            YFinalizationPromptModal,
        },

        mixins: [
            PageMixin,
        ],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.isEdit ? this.get(this.details, 'title', '') : this.$t('cart.claim.process'),
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('cart', this.$t('breadcrumbs.cart.cart')),
                this.$bc(this.$t('breadcrumbs.cart.claim.title')),
                this.$bc(this.$t('breadcrumbs.cart.claim.process')),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                claim   : null,
                details : null,
                statuses: [],
                status  : null,
            };
        },

        computed: {
            /**
             * Check if is edit mode
             */
            isEdit() {
                return this.$route.params.id && this.$route.params.id !== 'create';
            },

            /**
             * Claim Order
             */
            id() {
                return this.$route.params.id;
            },

            /**
             * Page title
             */
            pageHeadTitle() {
                return this.details
                    ? this.get(this.details, 'title', '')
                    : this.$t('cart.claim.process');
            },

            /**
             * Page Description - Claim Date
             */
            claimDate() {
                const claimDate = this.claim ? date(this.claim.raised_at, 'DD MMMM YYYY', this.$i18n.locale) : null;
                return claimDate ? this.$t('cart.claim.claim_date', [claimDate]) : null;
            },

            /**
             * Check if the claim is confirmed
             */
            confirmed() {
                return this.claim.status === 2 || this.claim.status === 1;
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.isEdit) {
                this.fetchClaim();
            }
        },

        methods: {
            /**
             * Fetch Claim for edit claim
             */
            fetchClaim() {
                this.$wait.start('loading-page');
                const params = {
                    id: this.id,
                };
                return this.$services.Cart.claimFetch(params).then((response) => {
                    this.claim = response.data.results;
                    this.statuses = response.data.metadata.statuses;
                    this.$set(this, 'status', this.claim.status);
                    this.fetchOrderDetails(this.claim.order_id);
                    if (this.claim) {
                        this.$set(this, 'status', this.claim.status);
                    }
                }).catch((error) => {
                    this.handleError(error);
                    this.$router.push({
                        name: 'cart-claims-list',
                    });
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Fetch order details
             *
             * @param id
             */
            fetchOrderDetails(id) {
                this.$wait.start('loading-order-details');
                this.details = null;
                return this.$services.Cart.orderDetails({ id }).then((response) => {
                    this.details = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-order-details');
                });
            },

            /**
             * Open modal for change status
             *
             * @param status
             */
            changeStatusModal(status) {
                this.$refs.ChangeClaimStatusModal.open(status);
            },

            /**
             * Cancel Status change
             */
            cancelStatus() {
                this.$set(this, 'status', this.claim.status);
            },
        },
    };

</script>
