<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="red"
            icon="md-file-cancel-outline"
        >
            <div class="d-f justify-content-between align-items-center">
                <p class="mb0">
                    {{ $t('cart.nodes.order_invoice.canceled', [data.idx]) }}
                </p>

                <y-button
                    tag="router-link"
                    size="sm"
                    :to="invoiceViewRoute(data.id)"
                    target="_blank"
                >
                    {{ $t('cart.actions.invoice.view') }}
                </y-button>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>

    import TimelineNode from '@/mixins/TimelineNode';

    export default {

        name: 'InvoiceCanceledNode',

        mixins: [TimelineNode],

        methods: {
            /**
             * Create route to the invoice
             *
             * @param id
             */
            invoiceViewRoute(id) {
                return {
                    name  : 'cart-invoices-single',
                    params: {
                        id,
                    },
                };
            },
        },

    };

</script>
