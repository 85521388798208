<template>
    <ul v-if="Array.isArray(content) && content.length">
        <li
            v-for="(item, index) in content"
            :key="index"
        >
            <span v-if="item.type === editTypes.payableAmount">
                {{ $t('cart.nodes.order_edited.payable_amount', [
                    numberFormat(item.old),
                    numberFormat(item.new),
                ]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.overallDiscount">
                {{ $t('cart.nodes.order_edited.overall_discount', [
                    numberFormat(item.old),
                    numberFormat(item.new),
                ]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.titleChanged">
                {{ $t('cart.nodes.order_edited.title_changed', [item.old, item.new]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.itemAdded">
                {{ $t('cart.nodes.order_edited.item_added', [item.subject]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.itemRemoved">
                {{ $t('cart.nodes.order_edited.item_removed', [item.subject]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.itemEdit">
                {{ $t('cart.nodes.order_edited.item_edit', [item.subject, item.old, item.new]) | digits }}
            </span>
            <span v-else-if="item.type === editTypes.expDateChanged">
                {{ $t('cart.nodes.order_edited.exp_date_changed', [
                    date(item.old, 'DD MMMM YYYY', $i18n.locale),
                    date(item.new, 'DD MMMM YYYY', $i18n.locale),
                ]) | digits }}
            </span>
        </li>
    </ul>
</template>

<script>
    import { date } from '@nodes/helpers/date';
    import { numberFormat } from '@nodes/helpers/number';

    export default {
        name: 'OrderEditionDiff',

        props: {
            content: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                /**
                 * supported edition types
                 */
                editTypes: {
                    payableAmount  : 'payable-amount',
                    overallDiscount: 'overall-discount',
                    titleChanged   : 'title-changed',
                    itemAdded      : 'item-added',
                    itemRemoved    : 'item-removed',
                    itemEdit       : 'item-edit',
                    expDateChanged : 'exp-date-changed',
                },
            };
        },

        methods: {
            date,
            numberFormat,
        },
    };
</script>
