<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-cart"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    v-if="isEdit"
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    wide
                    :disabled="totals.total < 0"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>

                <y-button
                    v-if="isEdit && canView"
                    tag="router-link"
                    :to="goToTimeline"
                >
                    {{ $t('cart.actions.details') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main v-if="settings">
                <form
                    id="main-form"
                    @submit.prevent="submit(save)"
                >
                    <div class="row">
                        <div class="col-sm-8">
                            <y-base-panel
                                v-model="model"
                                :settings="settings"
                            />

                            <y-wares-panel
                                v-model="model"
                                :settings="settings"
                            />

                            <y-total-panel
                                v-model="model.overall_discount"
                                :settings="settings"
                                :wares="model.items"
                                :shipping="model._shipping_method_id"
                                :currency-title="model"
                                :applied-discount="model._applied_discount_amount"
                                @total="getTotal"
                            />
                        </div>

                        <div class="col-sm-4">
                            <y-user-panel
                                v-model="model"
                                :settings="settings"
                            />

                            <y-shipping-panel
                                v-if="isOrder"
                                v-model="model"
                                :settings="settings"
                            />

                            <y-comment-panel v-model="model" />
                        </div>
                    </div>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YBasePanel from '@/modules/cart/components/orders/BasePanel';
    import YWaresPanel from '@/modules/cart/components/orders/WaresPanel';
    import YTotalPanel from '@/modules/cart/components/orders/TotalPanel';
    import YUserPanel from '@/modules/cart/components/orders/UserPanel';
    import YCommentPanel from '@/modules/cart/components/orders/CommentPanel';
    import YShippingPanel from '@/modules/cart/components/orders/ShippingPanel';

    export default {

        name: 'CartOrderEditor',

        components: {
            YBasePanel,
            YWaresPanel,
            YTotalPanel,
            YUserPanel,
            YCommentPanel,
            YShippingPanel,
        },

        mixins: [
            PageMixin,
            FormMixin,
        ],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$route.params.id ? this.get(this.model, '_title', '') : this.$t('cart.order.create'),
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            let type = this.$t('breadcrumbs.cart.pre.title');
            let title = this.$route.params.id ? this.$t('breadcrumbs.cart.pre.edit') : this.$t('breadcrumbs.cart.pre.create');

            if (this.$route.name.startsWith('cart-orders-')) {
                type = this.$t('breadcrumbs.cart.order.title');
                title = this.$route.params.id ? this.$t('breadcrumbs.cart.order.edit') : this.$t('breadcrumbs.cart.order.create');
            }

            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('cart', this.$t('breadcrumbs.cart.cart')),
                this.$bc('cart-orders-list', type),
                this.$bc(title),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    effected_at     : Math.round(Date.now() / 1000),
                    status          : 301,
                    overall_discount: 0,
                    items           : [],
                },
                totals  : {},
                settings: null,
            };
        },

        computed: {
            /**
             * Check if this is order
             */
            isOrder() {
                return this.$route.name.startsWith('cart-orders-');
            },

            /**
             * Check if page is editing pre-order
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Check view permission
             */
            canView() {
                return this.settings && this.settings.can_view;
            },

            /**
             * Return page title based on status
             */
            title() {
                if (this.isOrder) {
                    return this.isEdit ? this.get(this.model, '_title', '') : this.$t('cart.order.create');
                }
                return this.isEdit ? this.get(this.model, '_title', '') : this.$t('cart.pre.create');
            },

            /**
             * Push to the timeline page of the order
             */
            goToTimeline() {
                return {
                    name  : 'cart-details',
                    params: {
                        id: this.$route.params.id,
                    },
                };
            },
        },

        methods: {

            /**
             * Fetch order settings
             */
            fetch() {
                const api = this.$route.params.id ? 'orderFetch' : 'orderCreateFetch';
                let params = {}; // eslint-disable-line prefer-const
                if (this.$route.params.id) {
                    params.id = this.$route.params.id;
                } else {
                    params.slug = this.$route.params.slug;
                    params.pre_order = this.isOrder ? 0 : 1;
                }
                return this.$services.Cart[api](params).then((response) => {
                    this.settings = response.data.metadata;
                    if (this.isEdit) {
                        this.$set(this, 'model', response.data.results);
                    }
                });
            },

            /**
             * Save order
             */
            save() {
                const params = {
                    ...this.model,
                    type_slug: this.$route.params.slug,
                };
                if (!this.$route.params.id) {
                    params.is_pre_order = !this.isOrder;
                }
                return this.$services.Cart.orderSave(params).then((response) => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.isOrder ? this.$t('cart.order.name') : this.$t('cart.pre.name') }));

                    if (!this.isEdit) {
                        this.$router.push({
                            name  : 'cart-details',
                            params: {
                                id: response.data.metadata.hashid,
                            },
                        });
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Get Total Invoice Amount
             *
             * @param totals
             */
            getTotal(totals) {
                this.$set(this, 'totals', totals);
            },

        },

    };

</script>
