<template>
    <div>
        <keep-alive>
            <y-mega-menu
                v-show="!error"
                @error="error = true"
            />
        </keep-alive>

        <y-error404 v-if="error" />

        <template v-else>
            <main v-if="$route.name === 'cart'">
                <y-empty-state :message="$t('cart.widget.empty')" />
            </main>

            <router-view
                v-else
                :key="$route.path"
            />
        </template>
    </div>
</template>

<script>

    import GridPageMixin from '@/mixins/GridPage';
    import YMegaMenu from '@/modules/cart/components/MegaMenu';
    import YError404 from '@deps/Error404';
    import YEmptyState from '@deps/EmptyState';

    export default {

        name: 'CartWrapper',

        components: {
            YMegaMenu,
            YError404,
            YEmptyState,
        },

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.cart.cart')),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                error: false,
            };
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('cart.title'),
            };
        },

    };

</script>
