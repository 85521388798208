<template>
    <y-loading
        :active="$wait.is('loading-node-details')"
        height="100px"
    >
        <div
            v-if="content"
            class="content"
        >
            <table class="table bordered">
                <thead>
                    <tr>
                        <th>{{ $t('cart.table.ware') }}</th>
                        <th>{{ $t('cart.table.count') }}</th>
                        <th>{{ $t('cart.table.claim.count_on') }}</th>
                        <th>{{ $t('cart.table.raised_at') }}</th>
                        <th>{{ $t('cart.table.claim.reason') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="Array.isArray(content.claim_editor)">
                        <tr
                            v-for="(item, index) in content.claim_editor"
                            :key="`ci-${index}`"
                        >
                            <template v-if="item.active">
                                <td>
                                    <span>{{ item.title }}</span>
                                    <div class="mt10">
                                        <span
                                            v-if="item.claimable"
                                            class="label green"
                                        >
                                            {{ $t('cart.table.claim.ok') }}
                                        </span>
                                        <span
                                            v-else
                                            class="label red"
                                        >
                                            {{ $t('cart.table.claim.not_ok') }}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {{ item.count | digits }}
                                </td>
                                <td>
                                    {{ item.claimed_count | digits }}
                                </td>
                                <td>
                                    {{ content.effected_at | date('YYYY/MM/DD', $i18n.locale) }}
                                </td>
                                <td>
                                    {{ item.claimed_reason }}
                                </td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </y-loading>
</template>

<script>

    export default {

        name: 'ClaimCreatedNodeModal',

        props: {
            content: [Object, Array],
        },
    };

</script>
