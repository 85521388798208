<template>
    <y-modal
        ref="Modal"
        forced
    >
        <div slot="header">
            {{ $t('cart.claim.close.title') }}
        </div>

        <div slot="body">
            <p class="mb0">
                {{ $t('cart.claim.close.text') }}
            </p>
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="green"
                    loading-on="click-loading"
                    @click="confirm"
                >
                    {{ $t('cart.actions.claim_close') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>
    import { YModal } from '@deps';

    export default {
        name: 'FinalizationPromptModal',

        components: {
            YModal,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                id: null,
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param id
             */
            open(id) {
                this.$set(this, 'id', id);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Save form
             */
            confirm() {
                this.$wait.start('click-loading');
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Cart.claimFinalization(params).then(() => {
                    this.$emit('done');
                    this.$toast.success(this.$t('cart.claim.message.close.success'));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('click-loading');
                });
            },
        },
    };
</script>
