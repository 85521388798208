<template>
    <y-panel
        :title="title"
        class="inventory-processing-panel"
    >
        <div
            v-if="codes && codes.length"
            class="item-inventory-select"
        >
            <y-form-field
                v-model="temp"
                type="select"
                class="mb0"
                :options="codes ? codes : []"
                allow-empty
                multi
                :placeholder="$t('cart.claim.inventory_process.select_code')"
                no-label
                name="form"
            />
        </div>

        <y-empty-state
            v-else
            no-image
            class="mb0"
            height="100px"
            :message="$t('cart.claim.inventory_process.no_code')"
        />
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';
    
    export default {
        name: 'ItemInventory',

        components: {
            YFormField,
            YEmptyState,
        },

        props: {
            /**
             * Codes
             */
            allCodes: [Array, Object],

            /**
             * Codes
             */
            returnedCodes: [Array, Object],

            /**
             * Title
             */
            title: String,

            /**
             * List of selected
             */
            value: [String, Array, Object],

            /**
             * Read Only Mode
             */
            readOnly: Boolean,

            /**
             * Count
             */
            count: [Number, String],

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                search: null,
                codes : [],
                model : [],
                temp  : [],
            };
        },

        computed: {
            /**
             * Check of its replaced mode
             */
            isRerplaced() {
                return !!this.returnedCodes;
            },

            /**
             * Returned Codes
             */
            returned() {
                return this.returnedCodes;
            },
        },

        watch: {
            temp: {
                /**
                 * Fill model when select changed
                 */
                handler() {
                    this.tempToModel();
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.value && this.value.length > 0) {
                this.value.forEach((i) => {
                    this.temp.push(i);
                });
            }
                
            if (this.allCodes && this.allCodes.length) {
                this.comboCodes(this.allCodes);
            }
        },

        methods: {

            /**
             * Make the code combo friendly
             *
             * @param codes
             */
            comboCodes(codes) {
                Object.keys(codes).forEach((key) => {
                    this.addToCodes(codes[key]);
                });
            },

            /**
             * Add to the codes
             *
             * @param code
             */
            addToCodes(code) {
                this.codes.push({
                    value: code,
                    label: code,
                });
            },

            /**
             * Add item to selected
             */
            tempToModel() {
                this.model = [];
                this.temp.forEach((i) => {
                    this.model.push(i);
                });
                this.$emit('input', this.model);
            },
        },
    };

</script>
