<template>
    <y-panel :title="$t('cart.panel.shipping.title')">
        <y-form-field
            v-if="hasShippingMethods"
            v-model="model._shipping_method_id"
            type="select"
            value-field="id"
            label-field="title"
            name="shipping_method"
            :options="settings.available_shipping_methods"
            :disabled="!settings.shipping_method_enable"
            @select="selectMethod"
        />

        <y-form-field
            v-if="model._shipping_method_id"
            v-model="model._shipping_date"
            type="select"
            name="shipping_date"
            :options="dateOptions"
        />

        <y-form-field
            v-if="model._shipping_method_id"
            v-model="model._shipping_slot_id"
            type="select"
            name="shipping_time"
            :options="timeOptions"
        />

        <y-form-alert
            v-if="!hasShippingMethods"
            type="warning"
            :content="$t('cart.panel.shipping.no_shipping_methods')"
        />
    </y-panel>
</template>

<script>
    import { YFormField } from '@deps';
    import { YFormAlert } from '@deps/form/elements/Alert';

    export default {
        name: 'OrderShippingPanel',

        components: {
            YFormField,
            YFormAlert,
        },

        props: {
            settings: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model         : this.value || {},
                date          : null,
                selectedMethod: null,
            };
        },

        computed: {
            /**
             * Determine whether if any shipping methods is available
             */
            hasShippingMethods() {
                return Array.isArray(this.settings.available_shipping_methods) && this.settings.available_shipping_methods.length > 0;
            },

            /**
             * Return the Shipping Date
             */
            dateOptions() {
                return this.model._shipping_method_id
                    && this.selectedMethod
                    && this.selectedMethod.free_slots_panel_combos
                    && this.selectedMethod.free_slots_panel_combos.dates ? this.selectedMethod.free_slots_panel_combos.dates : [];
            },

            /**
             * Return the Shipping Date
             */
            timeOptions() {
                return this.model._shipping_method_id
                    && this.selectedMethod
                    && this.selectedMethod.free_slots_panel_combos
                    && this.selectedMethod.free_slots_panel_combos.times && this.model._shipping_date ? this.selectedMethod.free_slots_panel_combos.times[this.model._shipping_date] : [];
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.setSelects(this.value._shipping_method_id);
        },

        methods: {
            /**
             * Set Method data on init
             *
             * @param id
             */
            setSelects(id) {
                if (this.settings.available_shipping_methods && this.settings.available_shipping_methods.length) {
                    Object.keys(this.settings.available_shipping_methods).forEach((key) => {
                        if (this.settings.available_shipping_methods[key].id === id) {
                            this.$set(this, 'selectedMethod', this.settings.available_shipping_methods[key]);
                        }
                    });
                }
            },

            /**
             * Save the selected method data
             *
             * @param method
             */
            selectMethod(method) {
                this.$set(this, 'selectedMethod', method);
            },
        },
    };
</script>
