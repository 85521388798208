<template>
    <y-modal ref="Modal">
        <div slot="header">
            {{ title }}
        </div>

        <div slot="body">
            {{ text }}
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click.prevent.native="close"
            >
                {{ $t('button.cancel') }}
            </y-button>
            <y-button
                color="green"
                :loading="$wait.is('submitting-form')"
                @click.prevent.native="confirmAction"
            >
                {{ $t('button.confirm') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'InvoiceConfirmModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                type: null,
                id  : null,
            };
        },

        computed: {
            /**
             * Text of the confirm modal
             */
            title() {
                return this.type === 'generate' ? this.$t('cart.invoice.prompt.generate.title') : this.$t('cart.invoice.prompt.regenerate.title');
            },

            /**
             * Text of the confirm modal
             */
            text() {
                return this.type === 'generate' ? this.$t('cart.invoice.prompt.generate.text') : this.$t('cart.invoice.prompt.regenerate.text');
            },

            /**
             * Text of the toast message
             */
            toast() {
                return this.type === 'generate' ? this.$t('cart.invoice.message.generate') : this.$t('cart.invoice.message.regenerate');
            },
        },

        methods: {
            /**
             * Open modal
             *
             * @param id
             * @param type
             */
            open(id, type) {
                this.$set(this, 'type', type);
                this.$set(this, 'id', id);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Confirm Action
             */
            confirmAction() {
                return this.$services.Cart.invoiceSave({ id: this.id }).then(() => {
                    this.$toast.success(this.toast);
                    this.$emit('done');
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };

</script>
