<template>
    <y-loading
        :active="$wait.is('loading-node-details')"
        height="100px"
    >
        <div
            v-if="content"
            class="content"
        >
            <div class="row">
                <div class="col-12">
                    <y-details-panel
                        :cart="content"
                        no-panel
                    />
                </div>

                <div class="col-12">
                    <div class="table-wrapper">
                        <table class="table bordered">
                            <thead>
                                <tr>
                                    <th>{{ $t('cart.table.row') }}</th>
                                    <th>{{ $t('cart.table.ware') }}</th>
                                    <th>{{ $t('cart.table.count') }}</th>
                                    <th>{{ $t('cart.table.payment.discount') }}</th>
                                    <th>{{ $t('cart.table.payment.tax') }}</th>
                                    <th>{{ $t('cart.table.payment.original_price') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(row, index) in content.items"
                                    :key="index"
                                >
                                    <td>{{ (index + 1) | digits }}</td>
                                    <td>{{ row.ware_title | digits }}</td>
                                    <td>{{ row.count | digits }}</td>
                                    <td>
                                        <y-form-amount :element="{ value: row.order_price.discount }" />
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: row.order_price.tax }" />
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: row.order_price.price }" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-sm-4" />
                <div class="col-sm-8">
                    <div class="table-wrapper">
                        <table class="table bordered">
                            <tbody>
                                <tr>
                                    <td>
                                        {{ $t('cart.table.payment.total_amount') }}
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: content.total_price }" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $t('cart.table.payment.total_discount') }}
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: content.items_discount }" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $t('cart.table.payment.overall_discount') }}
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: content.overall_discount }" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $t('cart.table.payment.tax_total') }}
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: content.total_tax }" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $t('cart.table.payment.payable') }}
                                    </td>
                                    <td>
                                        <y-form-amount :element="{ value: content.payable_amount }" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </y-loading>
</template>

<script>

    import YFormAmount from '@deps/form/elements/Amount';
    import YDetailsPanel from '@/modules/cart/components/details/DetailsPanel';

    export default {
        name: 'PreOrderCreatedNodeModal',

        components: {
            YFormAmount,
            YDetailsPanel,
        },

        props: {
            content: [Object, Array],
        },

    };

</script>
