<template>
    <y-panel
        :title="item.title"
        class="inventory-processing-panel"
    >
        <template slot="functions">
            <div class="d-f">
                <form @submit.prevent="fetch">
                    <y-form-field
                        v-model="search"
                        type="text"
                        size="sm"
                        class="m0"
                        no-label
                        dir="auto"
                        :placeholder="$t('fields.search_code')"
                        :disabled="readOnly"
                    />
                </form>
                <y-button
                    type="reset"
                    size="sm"
                    icon="md-close"
                    :disabled="!search"
                    @click.prevent="clearSearch"
                />
            </div>
        </template>

        <table
            class="table inventory-processing-table tl-f"
            :class="{mb0: !hasError}"
        >
            <thead>
                <tr>
                    <th>{{ $t('cart.inventory_process.available_codes') }}</th>
                    <th>{{ $t('cart.inventory_process.selected', [model.length | 0]) | digits }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="va-t">
                        <div class="inventory-processing-col">
                            <y-loading
                                :active="$wait.is(loadingKey)"
                                height="100px"
                            >
                                <template v-if="codes && codes.length">
                                    <div
                                        v-for="code in codes"
                                        :key="`code-${code.value}`"
                                    >
                                        <a
                                            href="#"
                                            class="button xs mb5"
                                            :class="makeAvailableCodeClass(code.label)"
                                            @click.prevent="addToSelected(code.value.toString())"
                                        >
                                            {{ code.label | digits }}
                                        </a>
                                    </div>
                                </template>

                                <y-empty-state
                                    v-else
                                    no-image
                                    class="mb0"
                                    height="100px"
                                    :message="$t('cart.inventory_process.no_code')"
                                />
                            </y-loading>
                        </div>
                    </td>
                    <td class="va-t">
                        <div class="inventory-processing-col">
                            <div
                                v-for="el in model"
                                :key="`selected-code-${el}`"
                            >
                                <a
                                    href="#"
                                    class="button xs mb5 active"
                                    :class="makeSelectedCodeClass(el)"
                                    @click.prevent="removeFromSelected(el)"
                                >
                                    {{ el | digits }}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            v-if="hasError"
            class="p10"
        >
            <div class="panel-message danger mb0">
                <div
                    v-if="!isEnough"
                    class="title"
                >
                    {{ $t('cart.inventory_process.not_enough', [remaining]) | digits }}
                </div>
                <div
                    v-if="!areAvailable"
                    class="title"
                >
                    {{ $t('cart.inventory_process.not_available') }}
                </div>
            </div>
        </div>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';

    export default {

        name: 'InventoryProcessingItem',

        components: {
            YFormField,
            YEmptyState,
        },

        props: {

            /**
             * Cart item
             */
            item: Object,

            /**
             * List of selected
             */
            value: Array,

            /**
             * Read Only Mode
             */
            readOnly: Boolean,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                search    : null,
                codes     : null,
                loadingKey: `loading-codes-${this.item.id}`,

                model: [...(this.value || [])],
            };
        },

        computed: {

            /**
             * Check if there is any error on item
             */
            hasError() {
                return !this.isEnough || !this.areAvailable;
            },

            /**
             * Check if selected code is enough
             */
            isEnough() {
                return this.model.length === this.item.required_count * 1;
            },

            /**
             * Count of remain to be selected
             */
            remaining() {
                return this.item.required_count - this.model.length;
            },

            /**
             * Check if all selected codes are available
             */
            areAvailable() {
                return this.item.selected.every((el) => el.available);
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch inventory details
             */
            fetch() {
                this.$wait.start(this.loadingKey);
                const params = {
                    id  : this.item.id,
                    code: this.search,
                };
                this.$services.Market.inventoryUniquesCombo(params).then((response) => {
                    this.codes = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end(this.loadingKey));
            },

            /**
             * Add item to selected
             *
             * @param code
             */
            addToSelected(code) {
                if (this.model.length === this.item.required_count) {
                    return;
                }

                if (this.model.find((el) => el === code)) {
                    return;
                }

                this.model.push(code);
                this.emitModel();
            },

            /**
             * Remove item from selected
             *
             * @param code
             */
            removeFromSelected(code) {
                this.$set(this, 'model', this.model.filter((el) => el !== code));
                this.emitModel();
            },

            /**
             * Emit model
             */
            emitModel() {
                this.$emit('input', this.model);
            },

            /**
             * Make selected code class
             *
             * @param code
             */
            makeSelectedCodeClass(code) {
                const classes = [];

                const inSelected = this.item.selected.find((el) => el.code === code);

                if (inSelected) {
                    if (inSelected.available) {
                        classes.push('brdc-green bg-green-lightest');
                    } else {
                        classes.push('brdc-red bg-red-lightest');
                    }
                } else {
                    classes.push('brdc-green bg-green-lightest');
                }
                if (this.readOnly) {
                    classes.push('disabled');
                }

                return classes;
            },

            /**
             * Make available code class
             *
             * @param codeLabel
             */
            makeAvailableCodeClass(codeLabel) {
                const classes = [];
                if (this.model.find((el) => el === codeLabel) || this.readOnly) {
                    classes.push('disabled');
                }
                return classes;
            },

            /**
             * Clear search code
             */
            clearSearch() {
                this.search = null;
                this.fetch();
            },
        },
    };

</script>
