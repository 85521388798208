<template>
    <y-modal
        ref="Modal"
        class="cart-payment-management"
        size="lg"
        forced
        :title="$t('cart.payment_management.title')"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <div
                    class="cart-payment-management__info"
                    :class="{'less-amount': (info.paid_amount < info.payable_amount), 'over-amount': (info.paid_amount > info.payable_amount)}"
                >
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('cart.table.payment.payable') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: Math.abs(info.payable_amount) }" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('cart.table.payment.paid') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: Math.abs(info.paid_amount) }" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('cart.table.payment.diff') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: Math.abs(info.payable_amount - info.paid_amount) }" />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="info.editable"
                    class="cart-payment-management__form"
                >
                    <form
                        id="payment-form"
                        @submit.prevent="save"
                    >
                        <div class="row">
                            <div class="col">
                                <y-form-field
                                    v-model="model.method"
                                    type="select"
                                    :options="methods"
                                    label-field="title"
                                    value-field="slug"
                                    validation="required"
                                    name="payment_management.methods"
                                />
                            </div>
                            <div class="col">
                                <y-form-field
                                    v-model="model.type"
                                    type="select"
                                    :options="model.method === 'path' ? pathTypes : walletTypes"
                                    label-field="title"
                                    value-field="slug"
                                    validation="required"
                                    name="payment_management.type"
                                />
                            </div>
                        </div>

                        <div
                            v-if="model.method"
                            class="row"
                        >
                            <div class="col">
                                <y-form-field
                                    v-if="model.method === 'path'"
                                    v-model="model.path_id"
                                    type="select"
                                    :options="info.paths"
                                    label-field="title"
                                    value-field="id"
                                    validation="required"
                                    name="payment_management.paths"
                                />

                                <div v-if="model.method === 'wallet'">
                                    <y-form-field
                                        v-model="model.wallet_id"
                                        type="select"
                                        class="mb0"
                                        :options="info.wallets"
                                        label-field="label"
                                        value-field="value"
                                        validation="required"
                                        name="payment_management.wallets"
                                        @select="walletSelected($event)"
                                    />
                                    <p
                                        v-if="model.wallet_id"
                                        class="mb0 cart-payment-management__wallet-balance"
                                    >
                                        {{ `${$t('cart.payment_management.wallet_balance')}:` }}
                                        <y-form-amount
                                            v-if="maxWalletAmount"
                                            class="mb0 d-ib"
                                            :element="{ value: maxWalletAmount }"
                                        />
                                        <span v-else>{{ `0` | digits }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col">
                                <y-form-field
                                    v-model="model.amount"
                                    type="number"
                                    name="payment_management.amount"
                                    format="separated"
                                    validation="required"
                                />
                            </div>
                        </div>

                        <div
                            v-if="model.method && model.method === 'path'"
                            class="row"
                        >
                            <div class="col">
                                <y-form-date-picker
                                    v-model="model.effected_at"
                                    type="datetime"
                                    name="payment_management.effected_at"
                                />
                            </div>
                            <div class="col">
                                <y-form-field
                                    v-model="model.tracking_number"
                                    type="text"
                                    name="payment_management.tracking_number"
                                />
                            </div>
                        </div>

                        <div
                            v-if="info.receipt_uploader_rule"
                            class="row"
                        >
                            <div class="col">
                                <y-form-uploader
                                    v-model="model.receipt"
                                    name="payment_management.receipt"
                                    :max-file-size="info.receipt_uploader_rule.max_volume_files"
                                    :max-files="info.receipt_uploader_rule.max_number_files"
                                    :payload="info.receipt_uploader_rule.payload"
                                    :accepted-files="info.receipt_uploader_rule.allowed_extensions"
                                />
                            </div>
                        </div>

                        <div class="d-f justify-content-end">
                            <y-button
                                color="green"
                                :disabled="(model.type === 'out' && model.method === 'wallet' && maxWalletAmount) ? (model.amount > maxWalletAmount) : false"
                                form="payment-form"
                                loading-on="payment-form"
                            >
                                {{ $t('button.save') }}
                            </y-button>
                        </div>
                    </form>
                </div>

                <hr class="gray-lightest">

                <div class="cart-payment-management__transactions">
                    <y-loading
                        :active="$wait.is('fetching-transactions')"
                        height="50px"
                    >
                        <div
                            v-if="transactions && transactions.length"
                            class="table-wrapper"
                        >
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th
                                            v-for="column in transitions_table"
                                            :key="column.id"
                                            class="invoice-block-key no-dots"
                                        >
                                            {{ column.title }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(row, index) in transactions"
                                        :key="row.id"
                                    >
                                        <td class="invoice-block-value">
                                            {{ (index + 1) | digits }}
                                        </td>
                                        <td class="invoice-block-value">
                                            {{ row.path_title }}
                                        </td>
                                        <td class="invoice-block-value">
                                            {{ row.is_online ? $t('cart.payment_management.method_type.online') : $t('cart.payment_management.method_type.offline') }}
                                        </td>
                                        <td class="invoice-block-value">
                                            {{ row.type_label }}
                                        </td>
                                        <td
                                            class="invoice-block-value amount"
                                            :class="row.type"
                                        >
                                            <y-form-amount
                                                class="mb0 d-ib"
                                                :element="{ value: row.amount, postfix: info.currency.title }"
                                            />
                                        </td>
                                        <td
                                            v-if="!transactionStatus[index].active"
                                            class="invoice-block-value link"
                                            @click="changeStatus(null, index)"
                                        >
                                            {{ transactionStatus[index].new_status ? transactionStatus[index].new_status : row.status_label }}
                                        </td>
                                        <td
                                            v-else
                                            class="invoice-block-value status-form"
                                            colspan="3"
                                        >
                                            <div class="d-f">
                                                <y-form-field
                                                    v-model="status[index]"
                                                    type="select"
                                                    class="mb0"
                                                    :options="info.statuses"
                                                    label-field="label"
                                                    value-field="value"
                                                    name="payment_management.status"
                                                    no-label
                                                    @select="changeStatus($event, index, row.id)"
                                                />
                                                <y-button
                                                    icon="md-close"
                                                    class="close"
                                                    @click.prevent="changeStatus('close', index)"
                                                />
                                            </div>
                                        </td>

                                        <td
                                            v-if="!transactionStatus[index].active"
                                            class="invoice-block-value"
                                        >
                                            {{ row.effected_at ? dateHelper(row.effected_at) : `-` }}
                                        </td>

                                        <td
                                            v-if="!transactionStatus[index].active"
                                            class="invoice-block-value"
                                        >
                                            {{ row.bank_track_no ? digitHelper(row.bank_track_no) : `-` }}
                                        </td>

                                        <td
                                            v-if="!transactionStatus[index].active"
                                            class="invoice-block-value"
                                        >
                                            <a
                                                v-if="row.receipt && row.receipt.length"
                                                :href="row.receipt[0].link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {{ $t('cart.actions.view_receipt') }}
                                            </a>
                                            <span v-else>{{ `-` }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- EmptyState -->
                        <y-empty-state
                            v-else
                            height="250"
                            :message="$t('cart.payment_management.no_transactions')"
                        />
                    </y-loading>
                </div>
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { date } from '@nodes/helpers/date';
    import { digits } from '@nodes/helpers/number';
    import {
        YModal, YFormField, YFormDatePicker, YFormUploader,
    } from '@deps';
    import YEmptyState from '@deps/EmptyState';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {
        name: 'PaymentManagementModal',

        components: {
            YModal,
            YFormField,
            YFormUploader,
            YFormDatePicker,
            YFormAmount,
            YEmptyState,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                order_id    : null,
                info        : [],
                transactions: [],
                model       : {
                    amount: 0,
                },
                status: [],

                transactionStatus: [],
                maxWalletAmount  : 0,

                methods: [
                    {
                        slug : 'path',
                        title: this.$t('cart.payment_management.method.path'),
                    },
                ],

                pathTypes: [
                    {
                        slug : 'in',
                        title: this.$t('cart.payment_management.type.in'),
                    },
                    {
                        slug : 'out',
                        title: this.$t('cart.payment_management.type.out'),
                    },
                ],

                walletTypes     : [],
                walletTypesAdded: true,

                transitions_table: [
                    {
                        title: this.$t('cart.table.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('cart.table.payment.method'),
                        id   : 'method',
                    },
                    {
                        title: this.$t('cart.table.payment.method_type'),
                        id   : 'method_type',
                    },
                    {
                        title: this.$t('cart.table.payment.type'),
                        id   : 'type',
                    },
                    {
                        title: this.$t('cart.table.payment.amount'),
                        id   : 'amount',
                    },
                    {
                        title: this.$t('cart.table.status'),
                        id   : 'status',
                    },
                    {
                        title: this.$t('cart.table.effected_at'),
                        id   : 'effected_at',
                    },
                    {
                        title: this.$t('cart.table.tracking_number'),
                        id   : 'tracking_number',
                    },
                    {
                        title: this.$t('cart.table.receipt'),
                        id   : 'receipt',
                    },
                ],
            };
        },

        methods: {
            /**
             * Open modal
             *
             * @param id
             */
            open(id) {
                this.$set(this, 'order_id', id);
                this.fetch();
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('done');
            },

            /**
             * Reset data
             */
            reset() {
                this.model = {};
                this.uniques = null;
                this.other_conflicts = null;
            },

            /**
             * Fetch data
             *
             * @param refresh
             */
            fetch(refresh) {
                if (refresh) {
                    this.$wait.start('fetching-transactions');
                    this.transactions = [];
                }
                if (!refresh) {
                    this.$wait.start('fetching-data');
                }
                const params = {
                    id: this.order_id,
                };
                this.$services.Cart.paymentProcessingFetch(params).then((response) => {
                    this.info = response.data.results;
                    this.transactions = response.data.results.transactions;
                    this.createTransaction(this.transactions);
                    this.checkTypes(this.info);
                    this.checkWallet(this.info);
                    this.$set(this.model, 'amount', Math.abs(this.info.payable_amount - this.info.paid_amount));
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => {
                    this.$wait.end('fetching-data');
                    this.$wait.end('fetching-transactions');
                });
            },

            /**
             * Save Form
             */
            save() {
                this.$wait.start('payment-form');
                const params = {
                    ...this.model,
                    id: this.order_id,
                };
                this.$services.Cart.paymentProcessingSave(params).then(() => {
                    this.$toast.success(this.$t('cart.payment_management.message.success'));
                    this.$set(this.info, 'receipt_uploader_rule', null);
                    this.$set(this, 'model', {
                        amount: 0,
                    });
                    this.$validator.reset();
                    this.fetch(true);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('payment-form');
                });
            },

            /**
             * Push available tyles
             *
             * @param data
             */
            checkTypes(data) {
                if (this.walletTypesAdded) {
                    if (data && data.wallet_usable) {
                        this.walletTypes.push({
                            slug : 'in',
                            title: this.$t('cart.payment_management.type.in'),
                        });
                    }
                    if (data && data.wallet_refundable) {
                        this.walletTypes.push({
                            slug : 'out',
                            title: this.$t('cart.payment_management.type.out'),
                        });
                    }
                    
                    this.walletTypesAdded = false;
                }
            },

            /**
             * Add wallet if it is active
             *
             * @param data
             */
            checkWallet(data) {
                const has = this.methods.some((i) => i.slug === 'wallet');
                if (!has && data && data.wallet_active) {
                    this.methods.push({
                        slug : 'wallet',
                        title: this.$t('cart.payment_management.method.wallet'),
                    });
                }
            },

            /**
             * Create transactions array
             *
             * @param items
             */
            createTransaction(items) {
                if (items) {
                    items.forEach((i, index) => {
                        this.transactionStatus.push({
                            active    : false,
                            new_status: null,
                        });
                        this.$set(this.status, `${index}`, i.status);
                    });
                }
            },

            /**
             * Change transactions status
             *
             * @param event
             * @param index
             * @param id
             */
            changeStatus(event, index, id) {
                if (this.info.editable && !this.transactions[index].is_locked) {
                    this.$set(this.transactionStatus[index], 'active', true);
                    if (event && event !== 'close') {
                        this.$set(this.status, `${index}`, event.value);
                        const params = {
                            status  : this.status[index],
                            order_id: this.order_id,
                            id,
                        };
                        this.$services.Cart.paymentStatusChange(params).then(() => {
                            this.$set(this.transactionStatus[index], 'new_status', event.label);
                            this.$set(this.transactionStatus[index], 'active', false);
                            this.$toast.success(this.$t('cart.message.change_status.success', [event.label]));
                            this.fetch(true);
                        }).catch((error) => {
                            this.handleError(error);
                        });
                    }
                    if (event === 'close') {
                        this.$set(this.transactionStatus[index], 'active', false);
                    }
                }
            },

            /**
             * Wallet Selected
             *
             * @param event
             */
            walletSelected(event) {
                this.$set(this, 'maxWalletAmount', event.balance);
            },

            /**
             * Digit helper for using in inline condition
             *
             * @param value
             */
            digitHelper(value) {
                return digits(value, this.$i18n.locale);
            },

            /**
             * Date helper for using in inline condition
             *
             * @param value
             */
            dateHelper(value) {
                return date(value, 'YYYY/MM/DD', this.$i18n.locale);
            },
        },
    };

</script>
