<template>
    <y-panel :title="$t('cart.panel.user.title')">
        <template
            v-if="!isEdit"
            slot="functions"
        >
            <y-button
                size="xs"
                @click.prevent="$refs.UserModal.open()"
            >
                {{ $t('button.create') }}
            </y-button>
        </template>

        <y-form-field
            v-if="isEdit"
            v-model="model.customer.name_full"
            type="text"
            name="user"
            readonly
        />

        <y-form-field
            v-else
            v-model="model.user_id"
            type="select"
            name="order_user_search"
            :options="[]"
            :endpoint="userEndpoint"
            label-field="name_full"
            value-field="id"
            search="search"
            allow-empty
            :disabled="isEdit"
            validation="required"
        />
        <template v-if="model.user_id || isEdit">
            <y-form-field
                v-if="isEdit"
                v-model="model.address_id"
                type="select"
                name="address"
                class="mb0"
                :options="userAddresses"
                label-field="title"
                value-field="id"
                validation="required"
            />

            <y-form-field
                v-else
                v-model="model.address_id"
                type="select"
                name="address"
                class="mb0"
                :options="addresses"
                :disabled="$wait.is('loading-addresses')"
                :endpoint="addressEndpoint"
                label-field="title"
                value-field="id"
                search="search"
                validation="required"
            />

            <div class="ta-l mt10">
                <y-button
                    size="xs"
                    @click.prevent="$refs.AddressModal.open(userId)"
                >
                    {{ $t('cart.panel.address.create') }}
                </y-button>
            </div>
        </template>

        <!-- Modals -->
        <y-create-user-modal
            ref="UserModal"
            @done="addNewUser"
        />
        <y-create-address-modal
            ref="AddressModal"
            @done="fetchAddresses"
        />
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    import YCreateUserModal from '../CreateUserModal';
    import YCreateAddressModal from '../CreateAddressModal';

    export default {

        name: 'OrderUserPanel',

        components: {
            YFormField,
            YCreateUserModal,
            YCreateAddressModal,
        },

        props: {
            settings: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : this.value || {},
                addresses: [],

                userEndpoint: {
                    name  : 'cart-user-search',
                    params: {
                        slug: this.$route.params.slug,
                    },
                },

                addressEndpoint: {
                    name  : 'endpoint-master-list',
                    params: {
                        model: 'PersonLocation',
                    },
                },
            };
        },

        computed: {
            /**
             * Check if page is editing pre-order
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Return options for users
             */
            users() {
                if (this.has(this.model, 'id')) {
                    return [
                        {
                            id       : this.model.user_id,
                            name_full: this.model.name_full,
                        },
                    ];
                }

                return [];
            },

            /**
             * Return the user id
             */
            userId() {
                return this.model.user_id ? this.model.user_id : this.model.customer.id;
            },

            /**
             * Return Addresses
             */
            userAddresses() {
                return this.addresses && this.addresses.length ? this.addresses : this.settings.addresses;
            },
        },

        watch: {

            /**
             * Watch for user change and fetch user addresses
             */
            'model.user_id': {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    if (!this.isEdit) {
                        if (val) {
                            this.fetchAddresses();
                        } else {
                            this.model.address_id = null;
                            this.addresses = [];
                        }
                    }
                },
                immediate: true,
            },

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        methods: {
            /**
             * Add new user
             *
             * @param user
             */
            addNewUser(user) {
                this.users.push(user);
                this.$set(this.model, 'id', user.id);
                this.$set(this.model, 'name_full', user.name_full);
            },

            /**
             * Fetch user addresses
             */
            fetchAddresses() {
                this.$wait.start('loading-addresses');
                const params = {
                    user_id: this.userId,
                    type   : this.$route.params.slug,
                };
                this.$services.Cart.userAddressList(params).then((response) => {
                    this.addresses = response.data.results;
                }).finally(() => this.$wait.end('loading-addresses'));
            },
        },

    };

</script>
