<template>
    <y-modal
        ref="Modal"
        :title="$t('cart.inventory_process.title')"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <div :class="{disabled: $wait.is('saving-modal')}">
                    <template v-if="uniques && uniques.length">
                        <y-inventory-processing-item
                            v-for="item in uniques"
                            :key="`other-${item.id}`"
                            v-model="model[item.id]"
                            :item="item"
                            :read-only="isReadOnly"
                        />
                    </template>
                </div>

                <!-- Others -->
                <div
                    v-if="notEnoughtItems && notEnoughtItems.length"
                    class="panel-message danger"
                >
                    <div class="title">
                        {{ `${$t('cart.inventory_process.not_enough_error')}:` }}
                    </div>
                    <ul class="mt10">
                        <li
                            v-for="(el) in notEnoughtItems"
                            :key="`other-${el.id}`"
                        >
                            <span>
                                {{ el.title | digits }}
                                {{ $t('cart.inventory_process.inventory_details', [el.inventory, el.required_count]) | digits }}
                            </span>
                            <y-button
                                tag="router-link"
                                target="_blank"
                                size="xs"
                                rel="noreferrer noopener"
                                :to="{ name: 'panel-grid', params: el.link.params, query: el.link.query }"
                            >
                                {{ $t('cart.inventory_process.to_inventory_link') }}
                            </y-button>
                        </li>
                    </ul>
                </div>
                <div
                    v-if="enoughItems && enoughItems.length"
                    class="panel-message success"
                >
                    <div class="title">
                        {{ `${$t('cart.inventory_process.ok')}:` }}
                    </div>
                    <ul>
                        <li
                            v-for="(item) in enoughItems"
                            :key="`enough-${item.id}`"
                            class="title"
                        >
                            {{ item.title | digits }}
                            {{ $t('cart.inventory_process.inventory_details', [item.inventory, item.required_count]) | digits }}
                        </li>
                    </ul>
                </div>
            </y-loading>
        </div>

        <div
            v-if="!$wait.is('fetching-data')"
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>
            <y-button
                v-if="showSaveButton"
                color="green"
                loading-on="saving-modal"
                @click.native.prevent="save"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';
    import YInventoryProcessingItem from './InventoryProcessingItem';

    export default {

        name: 'InventoryProcessingModal',

        components: {
            YModal,
            YInventoryProcessingItem,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model          : {},
                enoughItems    : null,
                notEnoughtItems: null,
                uniques        : null,
                other_conflicts: null,
                isReadOnly     : false,
            };
        },

        computed: {
            /**
             * check whether to show save button according to available unique items and read-only mode
             */
            showSaveButton() {
                return !this.isReadOnly && Array.isArray(this.uniques) && this.uniques.length;
            },
        },

        methods: {

            /**
             * Fetch inventory details
             */
            fetch() {
                this.$wait.start('fetching-data');
                const params = {
                    id: this.$route.params.id,
                };
                this.$services.Cart.inventoryProcessingFetch(params).then((response) => {
                    this.isReadOnly = !response.data.metadata.editable;
                    this.uniques = response.data.results.uniques;
                    const nonUniques = response.data.results.non_uniques;
                    const enoughItems = [];
                    const notEnoughtItems = [];
                    nonUniques.forEach((item) => {
                        if (item.is_enough) {
                            enoughItems.push(item);
                        } else {
                            notEnoughtItems.push(item);
                        }
                    });

                    this.enoughItems = enoughItems;
                    this.notEnoughtItems = notEnoughtItems;

                    this.other_conflicts = response.data.results.other_conflicts;

                    this.model = response.data.results.uniques.reduce((acc, ware) => {
                        acc[ware.id] = ware.selected.reduce((arr, el) => {
                            arr.push(el.code);
                            return arr;
                        }, []);
                        return acc;
                    }, {});
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => this.$wait.end('fetching-data'));
            },

            /**
             * Open modal
             */
            open() {
                this.fetch();
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Reset data
             */
            reset() {
                this.model = {};
                this.uniques = null;
                this.other_conflicts = null;
            },

            /**
             * Save form
             */
            save() {
                if (!this.isReadOnly) {
                    this.$wait.start('saving-modal');
                    const params = {
                        id          : this.$route.params.id,
                        unique_codes: this.model,
                    };
                    this.$services.Cart.inventoryProcessingSave(params).then(() => {
                        this.$toast.success(this.$t('messages.save.success', { type: this.$t('cart.inventory_process.title') }));
                        this.close();
                    }).catch((error) => {
                        this.handleError(error);
                        this.close();
                    }).finally(() => this.$wait.end('saving-modal'));
                }
            },
        },
    };

</script>
