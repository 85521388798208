<template>
    <y-timeline-node
        v-bind="nodeBind"
        color="yellow"
        icon="md-cash-usd"
        is-alert
    >
        <p
            v-if="data.old_label"
            class="mb0"
            v-html="$t('cart.claim.message.change_claim_payment.success', [data.old_label, data.new_label])"
        />

        <p
            v-else
            class="mb0"
            v-html="$t('timeline.changeStatus.new', [data.new_label])"
        />
    </y-timeline-node>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'ClaimPaymentStatusChangedNode',

        mixins: [TimelineNode],
    };
</script>
