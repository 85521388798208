<template>
    <y-panel>
        <!-- Title -->
        <y-form-field
            id="title"
            v-model="model.custom_title"
            type="text"
            name="title"
        />

        <div class="row">
            <div class="col-sm-6">
                <y-form-date-picker
                    v-model="model.effected_at"
                    type="datetime"
                    name="raised_at"
                    format="YYYY/M/D HH:mm"
                    :disabled="isEdit"
                    :validation="!isEdit ? 'required' : ''"
                />
            </div>
            <div class="col-sm-6">
                <y-form-field
                    v-if="!isEdit"
                    v-model="model.status"
                    type="select"
                    name="status"
                    :options="settings.statuses"
                    validation="required"
                />
                <y-form-field
                    v-else
                    v-model="model._status_title"
                    type="text"
                    name="status"
                    readonly
                />
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <y-form-field
                    v-if="!isEdit"
                    v-model="model._currency_slug"
                    type="select"
                    name="currency"
                    :options="settings.currencies"
                    :disabled="value.id || singleCurrency"
                    validation="required"
                />

                <y-form-field
                    v-else
                    v-model="model._currency_title"
                    type="text"
                    name="currency"
                    readonly
                />
            </div>
            <div
                v-if="$route.name.includes('cart-pre-')"
                class="col-sm-6"
            >
                <y-form-date-picker
                    v-model="model.pre_expires_at"
                    type="datetime"
                    name="expires_at"
                    format="YYYY/M/D HH:mm"
                />
            </div>
        </div>
    </y-panel>
</template>

<script>

    import { YFormField, YFormDatePicker } from '@deps';

    export default {

        name: 'OrderBasePanel',

        components: {
            YFormField,
            YFormDatePicker,
        },

        props: {

            settings: Object,

            value: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {
            /**
             * Check if page is editing pre-order
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Check if there is just one currency, select it and disable the field
             */
            singleCurrency() {
                return (this.settings.currencies && this.settings.currencies.length === 1);
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        mounted() {
            /**
             * Fill currency field if there is just one currency
             */
            if (this.singleCurrency) {
                this.$set(this.model, '_currency_slug', this.settings.currencies[0].value);
            }
        },

    };

</script>
