<template>
    <div class="row cart-total-panel">
        <div class="col-sm-6" />
        <div class="col-sm-6">
            <table class="table bordered">
                <tbody>
                    <tr>
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.total_amount') }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="mb0"
                                :element="amountElement(totals.invoice)"
                            />
                        </td>
                    </tr>
                    <tr v-if="hasShippingMethod">
                        <th class="color-gray whs-nw">
                            {{ $t(`cart.table.payment.shipping_cost`) }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="mb0"
                                :element="amountElement(getShippingMethodPrice)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.overall_discount') }}
                        </th>
                        <td>
                            <y-form-field
                                v-model="model"
                                type="number"
                                name="overall_discount"
                                format="separated"
                                no-label
                                class="mb0 cart-total-panel__input"
                                size="sm"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.total_discount') }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="mb0"
                                :element="amountElement(totals.discount)"
                            />
                        </td>
                    </tr>
                    <tr v-if="settings.has_tax">
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.tax_total') }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="mb0"
                                :element="amountElement(totals.tax)"
                            />
                        </td>
                    </tr>
                    <tr v-if="hasCouponDiscount">
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.coupon_discount') }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="mb0"
                                :element="amountElement(appliedDiscount)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="color-gray whs-nw">
                            {{ $t('cart.table.payment.payable') }}
                        </th>
                        <td class="readonly">
                            <y-form-amount
                                class="form-title mb0"
                                :element="amountElement(totals.total)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    import { YFormField } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {

        name: 'OrderTotalPanel',

        components: {
            YFormField,
            YFormAmount,
        },

        props: {

            settings: Object,

            value: [Number, String],

            wares: Array,

            currencyTitle: [Object, Array],
            
            appliedDiscount: [String, Number],

            shipping: String,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || 0,

                totals: {
                    invoice : 0,
                    discount: 0,
                    tax     : 0,
                    total   : 0,
                },
            };
        },

        computed: {

            /**
             * Return currency title
             */
            currency() {
                return this.currencyTitle._currency_title ? this.currencyTitle._currency_title : this.currentCurrency;
            },

            /**
             * Current Currency
             */
            currentCurrency() {
                let label;
                if (!this.currencyTitle._currency_title) {
                    this.settings.currencies.forEach((crn) => {
                        if (crn.value === this.currencyTitle._currency_slug) {
                            label = crn.label;
                        }
                    });
                }
                return label;
            },

            /**
             * Check if page is editing pre-order
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Check if this is order
             */
            isOrder() {
                return this.$route.name.startsWith('cart-orders-');
            },


            /**
             * check whether coupon is enabled
             */
            hasCouponDiscount() {
                if (this.appliedDiscount >= 0 && this.isEdit && this.isOrder) {
                    return true;
                }
                return false;
            },

            /**
             * Return shipping method model
             */
            getShippingMethodPrice() {
                if (this.shipping) {
                    const shippingMethod = this.settings.available_shipping_methods.find((method) => method.id === this.shipping);
                    if (shippingMethod) {
                        return shippingMethod.freight;
                    }
                }
                return 0;
            },

            /**
             * Determine whether if the order has shipping method
             */
            hasShippingMethod() {
                return !!this.shipping;
            },

        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', newVal);
                    }
                },
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.calculateTotals();
                    this.$emit('input', this.model);
                },
            },

            getShippingMethodPrice: {
                /**
                 * Emit Total when shipping method changes
                 */
                handler() {
                    this.calculateTotals();
                    this.$emit('input', this.model);
                },
            },

            totals: {
                /**
                 * Emit Total when model changes
                 */
                handler() {
                    this.$emit('total', this.totals);
                },
            },

            wares: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.calculateTotals();
                },
                deep     : true,
                immediate: true,
            },

        },

        methods: {

            /**
             * Create amount element
             *
             * @param value
             */
            amountElement(value = 0) {
                return {
                    postfix : this.currency,
                    colorful: true,
                    value,
                };
            },

            /**
             * Calculate total amounts
             */
            calculateTotals() {
                if (!this.wares.length) {
                    this.$set(this, 'totals', {
                        invoice : 0,
                        discount: 0,
                        tax     : 0,
                        total   : 0,
                    });
                } else {
                    const invoice = this.wares.reduce((acc, item) => acc + item.order_price.price * item.count, 0);
                    const discount = (this.wares.reduce((acc, item) => acc + item.order_price.discount * item.count, 0) + +this.model);
                    let tax = 0;
                    const coupon = this.hasCouponDiscount ? this.appliedDiscount : 0;
                    let total = (invoice - discount - coupon ) || 0;
                    if (this.settings.has_tax) {
                        // eslint-disable-next-line no-nested-ternary
                        tax = this.wares.reduce((acc, item) => acc + (item.has_tax ? item.order_price._new_tax ? item.order_price._new_tax : item.order_price.tax : 0) * item.count, 0);
                    }
                    total = ((invoice - discount ) + tax) || 0;
                    if (this.hasShippingMethod) {
                        total += this.getShippingMethodPrice;
                    }
                    this.$set(this, 'totals', {
                        invoice,
                        discount,
                        tax,
                        total,
                    });
                }
            },

        },

    };

</script>
