<template>
    <y-modal
        ref="Modal"
        class="cart-payment-management"
        forced
        :title="$t('cart.shipping_processing.title')"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <div
                    v-if="hasAddress"
                    class="detail-item"
                >
                    <div class="title">
                        {{ $t('cart.details.customer.address') }}
                    </div>
                    <div class="value">
                        {{ info.address.province_name ? $t('cart.details.customer.province', [info.address.province_name]) : `` }}
                        {{ info.address.city_name ? $t('cart.details.customer.province', [info.address.city_name]) : `` }}
                        {{ info.address.address | digits }}
                    </div>
                </div>

                <y-alert
                    v-else
                    color="orange"
                    :message="$t('cart.shipping_processing.address_empty')"
                />

                <hr
                    v-if="hasAddress"
                    class="gray-lightest"
                >

                <form
                    v-if="info"
                    id="change-shipping-form"
                    @submit.prevent="save"
                >
                    <y-form-field
                        v-model="model.shipping_method"
                        type="select"
                        name="shipping_method"
                        :options="methods"
                        value-field="id"
                        label-field="title"
                        :disabled="!info.can_edit"
                        @select="selectMethod"
                    />

                    <y-form-field
                        v-if="model.shipping_method"
                        v-model="date"
                        type="select"
                        name="shipping_date"
                        :options="dateOptions"
                    />

                    <y-form-field
                        v-if="model.shipping_method"
                        v-model="model.shipping_slot"
                        type="select"
                        name="shipping_time"
                        :options="timeOptions"
                    />
                </form>

                <y-alert
                    v-if="info && info.has_invoice"
                    color="blue"
                    :message="$t('cart.shipping_processing.change_invoice')"
                />
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>

            <y-button
                color="green"
                form="change-shipping-form"
                :loading="$wait.is('submitting-form')"
                @click.prevent.native="save"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YFormField, YAlert } from '@deps';

    export default {
        name: 'ShippingProcessingModal',

        components: {
            YModal,
            YFormField,
            YAlert,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                order_id: null,
                info    : null,
                methods : [],
                model   : {
                    shipping_method: null,
                    shipping_slot  : null,
                },
                date          : null,
                selectedMethod: null,
            };
        },

        /**
         * @inheritdoc
         */
        computed: {
            /**
             * Check if address exist
             */
            hasAddress() {
                return this.info && this.info.address && (this.info.address.address !== '' || this.info.address.province_name || this.info.address.city_name);
            },

            /**
             * Return the Shipping Date
             */
            dateOptions() {
                return this.model.shipping_method && this.selectedMethod && this.selectedMethod.free_slots_panel_combos && this.selectedMethod.free_slots_panel_combos.dates ? this.selectedMethod.free_slots_panel_combos.dates : [];
            },

            /**
             * Return the Shipping Date
             */
            timeOptions() {
                return this.model.shipping_method && this.selectedMethod && this.selectedMethod.free_slots_panel_combos && this.selectedMethod.free_slots_panel_combos.times && this.date ? this.selectedMethod.free_slots_panel_combos.times[this.date] : [];
            },
        },

        methods: {
            /**
             * Open modal
             *
             * @param id
             */
            open(id) {
                this.$set(this, 'order_id', id);
                this.fetch();
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('done');
            },

            /**
             * Reset data
             */
            reset() {
                this.model = {
                    shpping_method: null,
                    shipping_slot : null,
                };
                this.info = null;
            },

            /**
             * Fetch data
             */
            fetch() {
                this.$wait.start('fetching-data');
                const params = {
                    id: this.order_id,
                };
                this.$services.Cart.shippingProcessingFetch(params)
                    .then((response) => {
                        this.info = response.data.results;
                        this.methods = response.data.results.methods;
                        if (response.data.results.current_method.id) {
                            this.$set(this.model, 'shipping_method', response.data.results.current_method.id);
                            this.$set(this, 'date', response.data.results.current_date.value);
                            this.$set(this.model, 'shipping_slot', response.data.results.current_time.value);
                            this.setSelects(this.model.shipping_method);
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                        this.close();
                    }).finally(() => {
                        this.$wait.end('fetching-data');
                    });
            },

            /**
             * Set Method data on init
             *
             * @param id
             */
            setSelects(id) {
                Object.keys(this.methods).forEach((key) => {
                    if (this.methods[key].id === id) {
                        this.$set(this, 'selectedMethod', this.methods[key]);
                    }
                });
            },

            /**
             * Save the selected method data
             *
             * @param method
             */
            selectMethod(method) {
                this.$set(this, 'selectedMethod', method);
            },

            /**
             * Save Form
             */
            save() {
                this.$wait.start('submitting-form');
                const params = {
                    ...this.model,
                    id: this.order_id,
                };
                this.$services.Cart.shippingProcessingSave(params).then(() => {
                    this.$toast.success(this.$t('cart.shipping_processing.message.success'));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },
        },
    };

</script>
