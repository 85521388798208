<template>
    <y-panel :title="$t('cart.panel.comment.title')">
        <!-- Comment -->
        <y-form-field
            id="comment"
            v-model="model._comment"
            type="textarea"
            name="comment"
            no-label
        />

        <y-form-field
            id="is_private"
            v-model="model._comment_is_private"
            type="checkbox"
            class="mb0"
            name="is_private"
        />
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {

        name: 'OrderBasePanel',

        components: {
            YFormField,
        },

        props: {

            settings: Object,

            value: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

    };

</script>
