<template>
    <!-- eslint-disable vue/no-deprecated-slot-scope-attribute -->
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="blue"
            icon="md-pencil"
            has-hood
        >
            <template
                slot="modal"
                slot-scope="data"
            >
                <ul class="p0 m0 lis-n">
                    <li
                        v-for="item in data.content.content"
                        :key="item.type"
                    >
                        <p v-if="item.type === 'count-changed'">
                            <i class="icon md-square color-blue ml5" />
                            {{ $t('cart.nodes.claim_edited.item_counts_changed', [item.old, item.new]) }}
                        </p>
                        <p v-else-if="item.type === 'item-added'">
                            <i class="icon md-square color-green ml5" />
                            {{ $t('cart.nodes.claim_edited.item_added', [item.subject]) | digits }}
                        </p>
                        <p v-else-if="item.type === 'item-removed'">
                            <i class="icon md-square color-red ml5" />
                            {{ $t('cart.nodes.claim_edited.item_removed', [item.subject]) | digits }}
                        </p>
                        <p v-else-if="item.type === 'item-edit-count'">
                            <i class="icon md-square color-red ml5" />
                            {{ $t('cart.nodes.claim_edited.item_edit_count', [item.subject, item.old, item.new]) | digits }}
                        </p>
                        <p v-else-if="item.type === 'item-edit-reason'">
                            <i class="icon md-square color-red ml5" />
                            {{ $t('cart.nodes.claim_edited.item_edit_reason', [item.subject, item.old, item.new]) | digits }}
                        </p>
                    </li>
                </ul>
            </template>
        </y-timeline-node>
    </div>
</template>

<script>

    import TimelineNode from '@/mixins/TimelineNode';

    export default {

        name: 'ClaimEditedNode',

        mixins: [TimelineNode],

    };

</script>
