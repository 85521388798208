<template>
    <y-modal
        ref="Modal"
        forced
        :title="$t('cart.coupon_processing.title')"
    >
        <div slot="body">
            <!-- Payment details box -->
            <div
                v-if="metadata"
                :class="paymentBoxClasses"
            >
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.payable') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: Math.abs(metadata.payable_amount) }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.paid') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: Math.abs(metadata.paid_amount) }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.table.payment.diff') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: Math.abs(metadata.payable_amount - metadata.paid_amount) }" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- New coupon code form -->
            <form @submit.prevent="onAddCouponCode">
                <div class="d-f mb10">
                    <y-form-field
                        v-model="newCode"
                        type="text"
                        class="m0"
                        :placeholder="$t('cart.coupon_processing.new_coupon')"
                        :disabled="disableCodeInput"
                        no-label
                    />
                    <y-button
                        color="green"
                        type="button"
                        loading-on="add-new-code"
                        :disabled="disableSubmitCode"
                        @click="onAddCouponCode"
                    >
                        {{ $t('cart.actions.add_coupon') }}
                    </y-button>
                </div>
            </form>
            <hr>
            <!-- Used coupons table -->
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <table
                    v-if="Array.isArray(couponCodes) && couponCodes.length"
                    class="table"
                >
                    <thead>
                        <tr>
                            <th>{{ $t('cart.table.row') }}</th>
                            <th>{{ $t('cart.table.coupon.code') }}</th>
                            <th>{{ $t('cart.table.coupon.rulebook') }}</th>
                            <th>{{ $t('cart.table.coupon.discount') }}</th>
                            <th>{{ $t('cart.table.actions') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(code, index) in couponCodes"
                            :key="index"
                        >
                            <td>{{ index + 1 | digits }}</td>
                            <td>{{ code.slug }}</td>
                            <td>{{ code.rulebook }}</td>
                            <td>
                                <y-form-amount
                                    class="mb0"
                                    :element="{ value: code.applied_discount }"
                                />
                            </td>
                            <td>
                                <y-dropdown
                                    :ref="`Dropdown-${code.slug}`"
                                    position="bottom-left"
                                >
                                    <template slot="toggle">
                                        <y-button
                                            type="button"
                                            color="red"
                                            size="sm"
                                            icon="md-delete"
                                            class="coupon-remove-btn"
                                            :loading-on="`delete-coupon-${code.slug}`"
                                        />
                                    </template>
                                    <a
                                        href="#"
                                        @click.prevent="onDeleteCouponCode(code.slug)"
                                    >
                                        <i class="icon md-check" />
                                        {{ $t('button.confirm') }}
                                    </a>
                                    <a
                                        href="#"
                                        @click.prevent="closeDropdown(code.slug)"
                                    >
                                        <i class="icon md-cancel" />
                                        {{ $t('button.cancel') }}
                                    </a>
                                </y-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <y-empty-state v-else />
            </y-loading>
        </div>
        <!-- Modal Actions -->
        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click.prevent="close"
            >
                {{ $t('button.close') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YDropdown } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount';
    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'CouponProcessingModal',

        components: {
            YModal,
            YFormAmount,
            YFormField,
            YEmptyState,
            YDropdown,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                couponCodes: [],
                newCode    : null,
                metadata   : null,
            };
        },

        computed: {
            /**
             * return current order hashid
             */
            id() {
                return this.$route.params.id;
            },

            /**
             * determine whether its possible to enter new code
             */
            disableCodeInput() {
                if (this.metadata) {
                    const currentCoupons = this.couponCodes.length;
                    const maxCoupons = Number(this.metadata.max_coupons);
                    return currentCoupons >= maxCoupons;
                }
                return true;
            },

            /**
             * Determine whether its possible to submit newly enter code
             */
            disableSubmitCode() {
                return this.disableCodeInput || !this.newCode;
            },

            /**
             * Compute payment box classes based on current payment status
             */
            paymentBoxClasses() {
                const classes = ['cart-payment-management__info'];
                const paidAmount = this.metadata.paid_amount;
                const payableAmount = this.metadata.payable_amount;
                if (paidAmount < payableAmount) {
                    classes.push('less-amount');
                } else if (paidAmount > payableAmount) {
                    classes.push('over-amount');
                }

                return classes;
            },
        },

        methods: {
            /**
             * Fetch Data
             */
            fetch() {
                this.$wait.start('fetching-data');
                const params = {
                    id: this.id,
                };
                this.$services.Cart.couponProcessingFetch(params).then((response) => {
                    this.couponCodes = response.data.results;
                    this.metadata = response.data.metadata;
                }).catch((err) => {
                    this.handleError(err);
                    this.close();
                }).finally(() => {
                    this.$wait.end('fetching-data');
                });
            },

            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('done');
                this.reset();
            },

            /**
             * reset component's state
             */
            reset() {
                this.newCode = null;
                this.couponCodes = null;
                this.metadata = null;
            },

            /**
             * save coupon processing form
             */
            onAddCouponCode() {
                this.$wait.start('add-new-code');
                const params = {
                    id  : this.id,
                    code: this.newCode,
                };
                this.$services.Cart.couponProcessingSave(params).then(() => {
                    this.newCode = null;
                    this.$toast.success(this.$t('cart.coupon_processing.message.add_coupon.success'));
                    this.fetch();
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('add-new-code');
                });
            },

            /**
             * Callback to handle code removal
             *
             * @param couponSlug
             */
            onDeleteCouponCode(couponSlug) {
                this.closeDropdown(couponSlug);
                this.$wait.start(`delete-coupon-${couponSlug}`);
                const params = {
                    id  : this.id,
                    code: couponSlug,
                };
                this.$services.Cart.OrderCouponMarkAsUnused(params).then(() => {
                    this.fetch();
                    this.$toast.success(this.$t('cart.coupon_processing.message.remove_coupon.success'));
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end(`delete-coupon-${couponSlug}`);
                });
            },

            /**
             * find dropwdown ref using its couponSlug and close it
             *
             * @param {string} couponSlug
             */
            closeDropdown(couponSlug) {
                const [dropdownRef] = this.$refs[`Dropdown-${couponSlug}`];
                dropdownRef.close();
            },
        },
    };

</script>
