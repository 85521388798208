<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="green"
            icon="md-plus"
            has-hood
        >
            <template #modal="data">
                <y-node-modal :content="data.content" />
            </template>
        </y-timeline-node>
    </div>
</template>

<script>

    import TimelineNode from '@/mixins/TimelineNode';
    import YNodeModal from './NodeModal';

    export default {

        name: 'InventoryConsumedNode',

        components: {
            YNodeModal,
        },

        mixins: [TimelineNode],

    };

</script>
