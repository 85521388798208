<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            :icon="iconName"
            :color="color"
            has-hood
        >
            <template #modal="data">
                <y-node-modal :content="data.content" />
            </template>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';
    import YNodeModal from './NodeModal';

    export default {

        name: 'PreOrderEditedNode',

        components: {
            YNodeModal,
        },

        mixins: [TimelineNode],

        computed: {
            /**
             * return whether a static icon or use a icon name from props
             */
            iconName() {
                return this.icon || 'md-pencil';
            },
        },

    };

</script>
