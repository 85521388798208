<template>
    <y-loading
        :active="$wait.is('loading-node-details')"
        height="100px"
    >
        <div
            v-if="content"
            class="content node-inventory-consumed"
        >
            <div v-if="content.uniques && content.uniques.length > 0">
                <span class="node-inventory-consumed__title">{{ $t('cart.table.unique_wares') }}</span>
                <div class="table-wrapper">
                    <table class="table bordered">
                        <thead>
                            <tr>
                                <th>{{ $t('cart.table.ware') }}</th>
                                <th>{{ $t('cart.table.count') }}</th>
                                <th>{{ $t('cart.table.codes') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="row in content.uniques"
                                :key="row.id"
                            >
                                <td>{{ row.title }}</td>
                                <td>{{ row.count | digits }}</td>
                                <td>
                                    <span
                                        v-for="(code, index) in row.selected"
                                        :key="index"
                                        class="label blue rounded unique-code"
                                    >{{ code.code | digits }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="content.others && content.others.length > 0">
                <span class="node-inventory-consumed__title">{{ $t('cart.table.other_wares') }}</span>
                <div class="table-wrapper">
                    <table class="table bordered">
                        <thead>
                            <tr>
                                <th>{{ $t('cart.table.title') }}</th>
                                <th>{{ $t('cart.table.count') }}</th>
                                <th>{{ $t('cart.table.dependent_wares') }}</th>
                            </tr>
                        </thead>
                        <tbody
                            v-for="row in content.others"
                            :key="row.id"
                        >
                            <tr class="other-wares">
                                <td>{{ row.title }}</td>
                                <td>{{ row.count | digits }}</td>
                                <td
                                    v-if="row.dependencies && row.dependencies.length > 0"
                                    class="dependency-wares"
                                >
                                    <div
                                        v-for="(item, index) in row.dependencies"
                                        :key="index"
                                        class="ware"
                                    >
                                        <span>{{ item.title }}</span>
                                        <span>{{ item.count | digits }}</span>
                                    </div>
                                </td>
                                <td v-else>
                                    {{ `-` }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </y-loading>
</template>

<script>

    export default {

        name: 'InventoryConsumedNodeModal',

        props: {
            content: [Object, Array],
        },

    };

</script>
