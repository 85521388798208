/*-----------------------------------------------------------------
- Register nodes
-----------------------------------------------------------------*/
import OrderCreatedNode from './OrderCreatedNode';
import PreOrderCreatedNode from './PreOrderCreatedNode';
import PreOrderEditedNode from './PreOrderEditedNode';
import InventoryConsumedNode from './InventoryConsumedNode';
import ClaimCreatedNode from './ClaimCreatedNode';
import ClaimEditedNode from './ClaimEditedNode';
import InvoiceCanceledNode from './InvoiceCanceledNode';
import InvoiceDocumentedNode from './InvoiceDocumentedNode';
import InvoiceGeneratedNode from './InvoiceGeneratedNode';
import InvoiceRegeneratedNode from './InvoiceRegeneratedNode';
import InvoiceReturnedNode from './InvoiceReturnedNode';
import PaymentPaidNode from './PaymentPaidNode';
import ClaimPaymentPaidNode from './ClaimPaymentPaidNode';
import ClaimPaymentStatusChangedNode from './ClaimPaymentStatusChangedNode';
import ClaimFinalizedNode from './ClaimFinalizedNode';
import OrderEditedNode from './OrderEditedNode';
import CouponUsedNode from './CouponUsedNode';
import CouponUnusedNode from './CouponUnusedNode';
import ShippingUsedNode from './ShippingUsedNode';
import ShippingCustomFieldsNode from './ShippingCustomFieldsNode';

export default {
    PreOrderCreatedNode,
    PreOrderEditedNode,
    OrderCreatedNode,
    OrderEditedNode,
    InventoryConsumedNode,
    InvoiceCanceledNode,
    InvoiceDocumentedNode,
    InvoiceGeneratedNode,
    InvoiceRegeneratedNode,
    InvoiceReturnedNode,
    ClaimCreatedNode,
    ClaimEditedNode,
    ClaimFinalizedNode,
    ClaimPaymentStatusChangedNode,
    ClaimPaymentPaidNode,
    CouponUsedNode,
    CouponUnusedNode,
    PaymentPaidNode,
    ShippingUsedNode,
    /**
     * TODO: back-end replaced 'ShippingCustomFieldsNode' with 'ShippingSentCustomFieldsNode' and 'ShippingDeliveredCustomFieldsNode
     * which are equal same as 'ShippingCustomFieldsNode'. so I keep original 'ShippingCustomFieldsNode' component.
     * feel free to refactor following line and remove this comment, after testing these two new nodes :)
     */
    ShippingCustomFieldsNode,
    ShippingSentCustomFieldsNode     : ShippingCustomFieldsNode,
    ShippingDeliveredCustomFieldsNode: ShippingCustomFieldsNode,
};
