<template>
    <div class="row invoice-row">
        <div class="col-12 invoice-subtitle-bg">
            <h3 class="invoice-subtitle">
                {{ title }}
            </h3>
        </div>
        <div
            v-if="item"
            class="col-12"
        >
            <div class="row invoice-block-row">
                <div class="col-print-4 col-12">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.name') }}</span>
                        <span
                            v-if="item.name"
                            class="invoice-block-value"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-else
                            class="invoice-block-value"
                        >{{ `-` }}</span>
                    </p>
                </div>
            </div>
            <div class="row invoice-block-row">
                <div class="col-print-4 col-12">
                    <div class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.address') }}</span>
                        <div
                            v-if="item.postal_address"
                            class="invoice-block-value"
                        >
                            {{ item.postal_address | digits }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row invoice-block-row">
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.email') }}</span>
                        <template v-if="item.email && item.email.length">
                            <span
                                v-for="(email, index) in item.email"
                                :key="index"
                                class="invoice-block-value d-ib auto-width"
                            >
                                {{ email | digits }}
                                <span
                                    v-if="index < item.email.length - 1"
                                    class="pl5"
                                >{{ ` - ` }}</span>
                            </span>
                        </template>
                        <span
                            v-else
                            class="invoice-block-value"
                        >
                            {{ `-` }}
                        </span>
                    </p>
                </div>
                <div class="col-print-2 col-12 col-sm-6">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.phone') }}</span>
                        <template v-if="item.fixed_tel && item.fixed_tel.length">
                            <span
                                v-for="(tel, index) in item.fixed_tel"
                                :key="index"
                                class="invoice-block-value d-ib auto-width"
                            >
                                {{ tel | digits }}
                                <span
                                    v-if="index < item.fixed_tel.length - 1"
                                    class="pl5"
                                >{{ ` - ` }}</span>
                            </span>
                        </template>
                        <span
                            v-else
                            class="invoice-block-value"
                        >
                            {{ `-` }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'YSellerInfo',

        props: {
            title: {
                type: String,
            },
            item: {
                type: [Array, Object],
            },
        },
    };

</script>
