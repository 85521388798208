<template>
    <y-loading
        :active="$wait.is('loading-node-details')"
        height="100px"
    >
        <div
            v-if="content"
            class="content"
        >
            <div
                class="cart-payment-management__info"
                :class="{'less-amount': (content.paid_amount < content.payable_amount), 'over-amount': (content.paid_amount > content.payable_amount)}"
            >
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.payment_management.info.payable') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: Math.abs(content.payable_amount) }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.payment_management.info.paid_to_customer') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: Math.abs(content.paid_amount) }" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.payment_management.info.currency') }}
                        </div>
                        <div
                            v-if="content.currency && content.currency.title"
                            class="value"
                        >
                            {{ content.currency.title }}
                        </div>
                    </div>
                </div>
            </div>
                
            <table
                v-if="content.items"
                class="table bordered"
            >
                <thead>
                    <tr>
                        <th>{{ $t('cart.table.ware') }}</th>
                        <th>{{ $t('cart.table.status') }}</th>
                        <th>{{ $t('cart.table.claim.count_on') }}</th>
                        <th>{{ $t('cart.table.claim.count_confirmed') }}</th>
                        <th>{{ $t('cart.table.claim.reason') }}</th>
                        <th>{{ $t('cart.claim.returned_code') }}</th>
                        <th>{{ $t('cart.claim.replaced_code') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in content.items"
                        :key="`ci-${index}`"
                    >
                        <td>
                            {{ item.ware_title }}
                        </td>
                        <td>
                            {{ item.status | digits }}
                        </td>
                        <td>
                            {{ item.claimed_count | digits }}
                        </td>
                        <td>
                            {{ item.confirmed_count | digits }}
                        </td>
                        <td>
                            {{ item.rejected_reason | digits }}
                        </td>
                        <td>
                            <span
                                v-for="(title, i) in item.replaced_codes"
                                :key="i"
                                class="label"
                            >
                                {{ title }}
                            </span>
                        </td>
                        <td>
                            <span
                                v-for="(title, i) in item.returned_codes"
                                :key="i"
                                class="label"
                            >
                                {{ title }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <y-empty-state
                v-else
                height="250"
                class="mb0"
                :message="$t('cart.claim.empty_claim_items')"
            />
        </div>
    </y-loading>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {

        name: 'ClaimCreatedNodeModal',

        components: {
            YEmptyState,
            YFormAmount,
        },

        props: {
            content: [Object, Array],
        },
    };

</script>
