<template>
    <div>
        <div class="panel timeline-header mb0">
            <article>
                <h5 class="title">
                    <i
                        class="icon md-history"
                        aria-label="History icon"
                    />
                    {{ $t('cart.timeline.title') }}
                </h5>

                <div
                    v-if="Array.isArray(filters)"
                    class="left"
                >
                    <y-form-field
                        type="select"
                        class="mb0"
                        :options="filters"
                        label-field="label"
                        value-field="value"
                        name="nodeStatus"
                        no-label
                        :placeholder="$t('cart.timeline.filter')"
                        value="all"
                        @select="filter($event)"
                    />
                </div>
            </article>
        </div>
        <y-timeline
            v-if="!isEmpty(timeline)"
            :nodes="RegisteredNodes"
            :timeline="timeline"
        />

        <y-empty-state
            v-else
            height="250"
            class="mb0"
            :message="$t('cart.nodes.undefined_node', [currentFilter])"
        />
    </div>
</template>

<script>

    import { YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';
    import YTimeline from '@deps/Timeline';

    import RegisteredNodes from '@/modules/cart/components/details/nodes';

    export default {

        name: 'CartDetailsTimeline',

        components: {
            YFormField,
            YEmptyState,
            YTimeline,
        },

        props: {
            cart   : Object,
            filters: [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                RegisteredNodes,

                currentFilter: null,
                timeline     : null,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$set(this, 'timeline', this.cart.timeline);
        },

        methods: {

            /**
             * Filter timeline
             *
             * @param event
             */
            filter(event) {
                this.currentFilter = event.label;
                if (event.value === 'all') {
                    this.timeline = this.cart.timeline;
                } else {
                    this.timeline = Object.values(this.cart.timeline).reduce((acc, node) => {
                        if (node.tags.includes(event.value)) {
                            acc[node.id] = node;
                        }
                        return acc;
                    }, {});
                }
            },

            /**
             * Check if object is empty
             *
             * @param obj
             */
            isEmpty(obj) {
                return Object.keys(obj).length === 0 && obj.constructor === Object;
            },

        },

    };
</script>
