<template>
    <div class="claim-process">
        <y-panel :title="$t('cart.claim.claim_item')">
            <table class="table bordered">
                <thead>
                    <tr>
                        <th>{{ $t('cart.table.ware') }}</th>
                        <th>{{ $t('cart.table.count') }}</th>
                        <th>{{ $t('cart.table.claim.count_on') }}</th>
                        <th>{{ $t('cart.table.raised_at') }}</th>
                        <th>{{ $t('cart.table.claim.reason') }}</th>
                        <th v-if="isConfirmed">
                            {{ $t('cart.table.actions') }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="claim && claim.claim_editor && claim.claim_editor.length">
                    <template v-for="(item, index) in claim.claim_editor">
                        <tr
                            v-if="item.claimed_count && item.claimed_count > 0"
                            :key="`ci-${index}`"
                        >
                            <td>{{ item.title }}</td>
                            <td>{{ item.count | digits }}</td>
                            <td>{{ (item.claimed_count ? item.claimed_count : `0`) | digits }}</td>
                            <td>{{ claim.raised_at | date('YYYY/MM/DD', $i18n.locale) }}</td>
                            <td>{{ item.claimed_reason }}</td>
                            <td
                                v-if="isConfirmed"
                                class="ta-c"
                            >
                                <y-button @click.prevent.native="$refs.inventoryProcessModel.open(item.id)">
                                    {{ $t('cart.actions.ware_process') }}
                                </y-button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </y-panel>

        <y-inventory-process-modal
            ref="inventoryProcessModel"
            :claim-id="claim.id"
            @done="$emit('done')"
        />
    </div>
</template>

<script>

    import YInventoryProcessModal from '@/modules/cart/components/claims/modals/InventoryProcess';

    export default {
        name: 'ClaimProcess',

        components: {
            YInventoryProcessModal,
        },

        props: {
            claim    : [Object, Array],
            confirmed: Boolean,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: {},
            };
        },

        computed: {
            /**
             * Check if claim status is confirmed
             */
            isConfirmed() {
                return this.confirmed;
            },
        },
    };

</script>
