<template>
    <div>
        <y-panel-grid :service="$services.Cart.orderGrid" />
    </div>
</template>

<script>

    // Mixins
    import GridPageMixin from '@/mixins/GridPage';

    export default {

        name: 'CartOrdersList',

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('cart.order.title'),
            };
        },
    };
</script>
