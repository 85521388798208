<template>
    <y-modal
        ref="Modal"
        size="lg"
        :title="$t(`cart.${type}.status.title`)"
    >
        <div slot="body">
            <div class="status-current">
                <span class="status-current__title">
                    {{ `${$t(`cart.${type}.status.current_status`)}:` }}
                </span>
                <span
                    class="status-current__status orange"
                    :class="statusClass"
                >
                    {{ oldStatus.status_title }}
                </span>
                <span class="status-current__title">
                    {{ `${$t(`cart.${type}.status.new_status`)}:` }}
                </span>
                <span
                    class="status-current__status teal"
                    :class="statusClass"
                >
                    {{ newStatus.label }}
                </span>
            </div>
            <hr class="primary">

            <y-alert
                v-if="metadata && metadata.shipping_required"
                color="orange"
                :message="$t('cart.details.change_status_address_empty')"
            />
                
            <div v-else>
                <y-form
                    v-model="model"
                    :params="form"
                />
            </div>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                @click.prevent.native="close"
            >
                {{ $t('button.cancel') }}
            </y-button>
            <y-button
                color="green"
                form="change-status-form"
                :loading="$wait.is('submitting-form')"
                :disabled="metadata && metadata.shipping_required"
                @click.prevent.native="formSent"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YForm, YAlert } from '@deps';
    import statusLabelMixin from '@/modules/cart/mixins/statusLabel';

    export default {
        name: 'YChangeOrderStatusModal',

        components: {
            YModal,
            YForm,
            YAlert,
        },

        mixins: [
            statusLabelMixin,
        ],

        /**
         * @inheritDoc
         */
        data() {
            return {
                newStatus: {},
                oldStatus: {},
                form     : null,
                model    : null,
                metadata : null,
            };
        },

        computed: {
            /**
             * Return the type
             */
            type() {
                return this.oldStatus.is_pre_order ? 'pre' : 'order';
            },
        },

        methods: {
            /**
             * Open modal
             *
             * @param params
             */
            open(params) {
                const {
                    metadata,
                    form,
                    model,
                    newStatus,
                    oldStatus,
                } = params;
                this.metadata = metadata;
                this.model = model;
                this.newStatus = newStatus;
                this.oldStatus = oldStatus;
                this.form = form;
                this.$refs.Modal.open();
                this.setStatusClass(oldStatus.value);
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Form Sent Status
             */
            formSent() {
                this.$wait.start('submitting-form');
                const params = {
                    ...this.model,
                    id    : this.oldStatus.id,
                    status: this.newStatus.value,
                };
                this.$services.Cart.orderStatusSet(params).then(() => {
                    this.close();
                    this.$toast.success(this.$t('cart.message.change_status.success', [this.newStatus.label]));
                    this.$emit('done');
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },
        },
    };

</script>
