<template>
    <div>
        <!-- Search Form -->
        <div class="panel claim-search">
            <div class="claim-search__wrapper">
                <form
                    id="search-form"
                    @submit.prevent="search(query)"
                >
                    <y-form-field
                        v-model="query"
                        type="text"
                        name="q"
                        class="mb0"
                        no-label
                        size="lg"
                        block
                        :placeholder="$t('fields.search_order')"
                    />
                </form>
                <y-button
                    form="search-form"
                    color="blue"
                >
                    {{ $t('button.search') }}
                </y-button>
            </div>
        </div>

        <y-loading
            :active="$wait.is('loading-search')"
            height="calc( 400px )"
        >
            <!-- Result List -->
            <div>
                <div
                    v-if="searched"
                    class="panel claim-search__list"
                >
                    <div class="claim-search__title">
                        <div class="claim-search__title__content">
                            <span class="claim-search__title__text">{{ $t('cart.claim.search.result') }}</span>
                            <span class="claim-search__title__query">{{ query }}</span>
                        </div>
                        <y-button @click.prevent="resetSearch">
                            {{ $t('cart.claim.search.again') }}
                        </y-button>
                    </div>
                </div>

                <div
                    v-if="results && results.length > 0"
                    class="table-wrapper"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('cart.table.title') }}</th>
                                <th>{{ $t('cart.table.customer.name') }}</th>
                                <th>{{ $t('cart.table.tracking_number') }}</th>
                                <th>{{ $t('cart.table.raised_at') }}</th>
                                <th>{{ $t('cart.table.delivered_at') }}</th>
                                <th>{{ $t('cart.table.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="result in results"
                                :key="result.id"
                            >
                                <td>
                                    <router-link :to="cartDetailsRoute(result.id)">
                                        {{ result.title | digits }}
                                    </router-link>
                                </td>
                                <td>{{ result.customer.name_full | digits }}</td>
                                <td>{{ result.tracking_number | digits }}</td>

                                <td v-if="result.effected_at">
                                    {{ result.effected_at | date('YYYY/MM/DD', $i18n.locale) }}
                                </td>
                                <td v-else>
                                    {{ `-` }}
                                </td>

                                <td v-if="result.given_at">
                                    {{ result.given_at | date('YYYY/MM/DD', $i18n.locale) }}
                                </td>
                                <td v-else>
                                    {{ `-` }}
                                </td>

                                <td>
                                    <y-button
                                        v-if="result.can_claim_admin"
                                        tag="a"
                                        @click.prevent="openClaim(result.id, result.active_claim_id)"
                                    >
                                        {{ result.active_claim_id ? $t('cart.claim.edit') : $t('cart.claim.create') }}
                                    </y-button>
                                    <span v-else>
                                        {{ $t('cart.claim.un_claimable') }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- EmptyState -->
            <y-empty-state
                v-if="results && !results.length"
                class="claim-search__empty"
                height="250"
                :message="emptyMessage"
            />
        </y-loading>
    </div>
</template>

<script>

    import { YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'ClaimSearch',

        components: {
            YFormField,
            YEmptyState,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                query  : null,
                results: [],

                // Control Visibility
                searched: false,
            };
        },

        computed: {
            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Empty message base on searched or have results
             */
            emptyMessage() {
                if (this.searched && this.results && !this.results.length) {
                    return this.$t('cart.claim.search.no_result');
                }
                return this.$t('cart.claim.search.first_use');
            },
        },

        watch: {
            query: {
                /**
                 * reset search if query changed
                 */
                handler() {
                    this.searched = false;
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (!this.isEdit) {
                this.search();
            }
        },

        methods: {
            /**
             * Do Search and fetch last 10 results without search
             *
             * @param query
             */
            search(query) {
                const params = {
                    type     : this.$route.params.slug,
                    including: ['title', 'effected_at', 'customer', 'given_at', 'full_code', 'active_claim_id', 'tracking_number', 'can_claim_admin'],
                };
                if (query) {
                    params.search = query;
                    this.searched = true;
                }
                this.$wait.start('loading-search');
                this.results = [];
                return this.$services.Cart.claimOrdersSearch(params).then((response) => {
                    this.results = [];
                    this.results = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-search');
                });
            },

            /**
             * Reset search result
             */
            resetSearch() {
                this.searched = false;
                this.query = null;
                this.results = [];
                this.search();
            },

            /**
             * Go to details page
             *
             * @param id
             */
            cartDetailsRoute(id) {
                return {
                    name  : 'cart-details',
                    params: {
                        id,
                    },
                };
            },

            /**
             * Open claim form
             *
             * @param orderId
             * @param claim
             */
            openClaim(orderId, claim) {
                if (claim) {
                    this.$router.push({
                        name  : 'cart-claims-edit',
                        params: {
                            id: claim,
                        },
                    });
                } else {
                    this.$emit('create', orderId);
                }
            },
        },
    };

</script>
