<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            color="blue"
            icon="md-pulse"
        >
            <template v-for="(field, index) in data.fields">
                <div
                    v-if="field.type !== 'uploader'"
                    :key="index"
                    class="node-shipping-custom-field"
                >
                    <p class="node-shipping-custom-field__title">
                        {{ `${field.title}: ` }}
                    </p>

                    <p class="node-shipping-custom-field__value">
                        {{ value(field) }}
                    </p>
                </div>
            </template>
        </y-timeline-node>
    </div>
</template>

<script>
    import { date } from '@nodes/helpers/date';

    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'ShippingCustomFieldsNode',

        mixins: [TimelineNode],

        methods: {
            /**
             * Return Proper Value
             *
             * @param field
             */
            value(field) {
                let { value } = field;
                if (field.type === 'date') {
                    value = date(field.value, 'DD MMMM YYYY HH:m', this.$i18n.locale);
                }
                return value;
            },
        },
    };
</script>
