<template>
    <y-panel
        :key="keyHelper"
        :title="$t('cart.panel.ware.title')"
        class="cart-wares-panel"
    >
        <template slot="functions">
            <y-button
                v-if="showAddButton"
                size="xs"
                :disabled="!model._currency_slug"
                @click.native.prevent="addRow"
            >
                {{ $t('button.add') }}
            </y-button>
        </template>

        <y-empty-state
            v-if="!model._currency_slug"
            height="200px"
            no-image
            :message="$t('cart.panel.ware.empty_currency')"
        />

        <y-empty-state
            v-else-if="!model.items.length"
            height="200px"
            :message="$t('cart.panel.ware.empty_ware')"
        />

        <table
            v-else
            class="table bordered mb0"
        >
            <thead>
                <tr>
                    <th>{{ $t('cart.table.ware') }}</th>
                    <th>{{ $t('cart.table.count') }}</th>
                    <th>{{ $t('cart.table.payment.unit_price') }}</th>
                    <th>{{ $t('cart.table.payment.unit_discount') }}</th>
                    <th v-if="settings.has_tax">
                        {{ $t('cart.table.payment.unit_tax') }}
                    </th>
                    <th>{{ $t('cart.table.payment.total_amount') }}</th>
                    <th class="col-min" />
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(wareRow, index) in model.items"
                    :key="`ware-row-${index}`"
                >
                    <td
                        v-if="wareRow.ware_id && wareRow.ware_title"
                        class="readonly"
                    >
                        <span class="cart-wares-panel__ware-title">{{ wareRow.ware_title }}</span>
                        <span class="cart-wares-panel__ware-code">{{ wareRow._ware_code }}</span>
                    </td>
                    <td
                        v-else
                        class="p0"
                        :colspan="wareRow.ware_id ? 1 : settings.has_tax ? 6 : 5"
                    >
                        <y-form-field
                            :key="`ware-${wareRow.ware_id}-${keyHelper}`"
                            v-model="model.items[index].ware_id"
                            type="select"
                            :endpoint="endpoint"
                            :options="[]"
                            search="search"
                            label-field="full_title"
                            value-field="id"
                            no-label
                            :placeholder="$t('cart.panel.ware.search_ware')"
                            class="mb0 cart-wares-panel__select"
                            @select="setDetails($event, index)"
                        />
                    </td>
                    <td v-if="wareRow.ware_id">
                        <y-form-field
                            v-model="model.items[index].count"
                            type="number"
                            no-label
                            class="mb0 cart-wares-panel__input"
                        />
                    </td>
                    <td v-if="wareRow.ware_id">
                        <y-form-field
                            v-model="model.items[index].order_price.price"
                            type="number"
                            format="separated"
                            no-label
                            class="mb0 cart-wares-panel__input"
                            @input="calculateTax(wareRow, index)"
                        />
                    </td>
                    <td v-if="wareRow.ware_id">
                        <y-form-field
                            v-model="model.items[index].order_price.discount"
                            type="number"
                            format="separated"
                            no-label
                            class="mb0 cart-wares-panel__input"
                            @input="calculateTax(wareRow, index)"
                        />
                    </td>
                    <td
                        v-if="settings.has_tax && wareRow.ware_id"
                        class="readonly"
                    >
                        <div class="d-f align-items-center">
                            <y-form-checkbox
                                v-model="model.items[index].has_tax"
                                no-label
                                :style="checkboxStyle"
                                :name="`row-${index}`"
                            />
                            <span class="pos-r tax-value">
                                <y-form-amount :element="{ value: tax(wareRow) }" />
                            </span>
                        </div>
                    </td>
                    <td
                        v-if="wareRow.ware_id"
                        class="readonly"
                    >
                        <y-form-amount
                            v-if="Number(model.items[index].order_price.discount) === 0"
                            :element="{ value: total(model.items[index], index) }"
                        />

                        <div v-else>
                            <y-form-amount
                                class="cart-wares-panel__original-price"
                                :element="{ value: total(model.items[index], index, false, true) }"
                            />
                            <y-form-amount
                                class="cart-wares-panel__discount-price"
                                :class="{'negative': total(model.items[index], index, true) < 0}"
                                :element="{ value: total(model.items[index], index, true) }"
                            />
                        </div>
                    </td>
                    <td class="col-min">
                        <y-button
                            :aria-label="$t('button.delete')"
                            size="xs"
                            icon="md-trash-can-outline"
                            class="color-red"
                            @click.native.prevent="deleteRow(index)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </y-panel>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import { YFormField, YFormCheckbox } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount';
    import YEmptyState from '@deps/EmptyState';

    export default {

        name: 'OrderWaresPanel',

        components: {
            YFormField,
            YFormCheckbox,
            YFormAmount,
            YEmptyState,
        },

        props: {

            settings: Object,

            value: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model      : this.value || {},
                keyHelper  : generateId(),
                useNewPrice: false,

                newRow: {
                    ware_title : null,
                    id         : null,
                    ware_id    : null,
                    count      : 1,
                    has_tax    : false,
                    order_price: {
                        price   : 0,
                        discount: 0,
                        tax     : 0,
                        _new_tax: null,
                    },
                },
            };
        },

        computed: {

            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Currency Id
             */
            currency() {
                return this.value._currency_slug;
            },

            /**
             * Search Ware
             */
            endpoint() {
                return {
                    name  : 'cart-ware-search',
                    params: {
                        slug    : this.$route.params.slug,
                        currency: this.currency,
                    },
                };
            },

            /**
             * whether to show add button according to model items
             */
            showAddButton() {
                return (this.model.items.length === 0) || (this.model.items[(this.model.items.length) - 1].ware_id);
            },

            /**
             * Checkbox style fix
             */
            checkboxStyle() {
                return {
                    marginTop: '-5px',
                };
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep     : true,
                immediate: true,
            },

            'model._currency_slug': {
                /**
                 * Add new row when currency changed, if there is no items
                 */
                handler() {
                    if (this.model._currency_slug && !this.isEdit) {
                        this.model.items = [];
                        this.addRow();
                    }
                },
            },

        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.keyHelper = generateId();
        },

        /**
         * Add new row is create mode
         */
        created() {
            if (!this.value.id && this.model._currency_slug) {
                this.addRow();
            }
        },

        methods: {

            /**
             * Add new row to items
             */
            addRow() {
                const count = this.model.items.length;
                if (count === 0 || (this.model.items && this.model.items[count - 1].ware_id)) {
                    this.$set(this.model, 'items', [...this.model.items, { ...this.newRow }]);
                }
            },

            /**
             * Delete row from items
             *
             * @param index
             */
            deleteRow(index) {
                const items = this.model.items.filter((el, i) => i !== index);
                this.$set(this.model, 'items', items);
            },

            /**
             * Calculate total
             *
             * @param ware
             * @param index
             * @param withDiscount
             * @param originalTax
             */
            total(ware, index, withDiscount = false, originalTax = false) {
                const discount = withDiscount ? ware.order_price.discount : 0;
                const tax = originalTax ? (ware.order_price.price * ware.tax_percent) / 100 : this.tax(ware);
                if (this.settings.has_tax && ware.has_tax) {
                    return (ware.count * ((ware.order_price.price - discount) + tax));
                }
                return (ware.order_price.price - discount) * ware.count;
            },

            /**
             * Calculate tax based on new data
             *
             * @param ware
             * @param index
             * @param withDiscount
             */
            calculateTax(ware, index, withDiscount = true) {
                let tax;
                if (ware.order_price.price === ware.order_price.discount) {
                    tax = 0;
                } else if (withDiscount) {
                    tax = ((ware.order_price.price - ware.order_price.discount) * ware.tax_percent) / 100;
                } else {
                    tax = (ware.order_price.price * ware.tax_percent) / 100;
                }
                this.$set(this.model.items[index].order_price, '_new_tax', tax);
            },

            /**
             * Return Tax
             *
             * @param ware
             */
            tax(ware) {
                return ware.order_price._new_tax || ware.order_price._new_tax === 0
                    ? ware.order_price._new_tax
                    : ware.order_price.tax;
            },

            /**
             * Set ware details
             *
             * @param details
             * @param index
             */
            setDetails(details, index) {
                let isDuplicate = false;
                this.model.items.forEach((ware) => {
                    if (details.id === ware.ware_id) {
                        const count = Number(ware.count) + 1;
                        this.$set(ware, 'count', count);
                        isDuplicate = true;
                        this.deleteRow(index);
                    }
                });
                if (!isDuplicate) {
                    const params = {
                        id  : details.id,
                        type: this.$route.params.slug,
                    };
                    this.$services.Cart.wareFetch(params)
                        .then((response) => {
                            const { results } = response.data;
                            this.$set(this.model.items, index, {
                                ...this.model.items[index],
                                ware_title : results.full_title,
                                _ware_code : results.code,
                                id         : null,
                                ware_id    : results.id,
                                count      : 1,
                                has_tax    : results.has_tax,
                                tax_percent: results.tax_percent,
                                order_price: {
                                    price   : results.order_price.price,
                                    discount: results.order_price.discount,
                                    tax     : results.order_price.tax,
                                    _new_tax: null,
                                },
                            });
                            this.keyHelper = generateId();
                            this.$set(this.model.items[index], 'has_tax', this.settings.has_tax);
                            this.addRow();
                        })
                        .catch((err) => {
                            this.handleError(err);
                        });
                }
            },

            /**
             * Set ware row options array
             *
             * @param ware
             */
            wareRowOptions(ware) {
                if (ware.ware_id) {
                    return [
                        {
                            id   : ware.ware_id,
                            title: ware.title,
                        },
                    ];
                }

                return [];
            },

            /**
             * Check if the price is changed
             *
             * @param index
             * @param oldPrice
             * @param newPrice
             */
            priceChanged(index, oldPrice, newPrice) {
                if (oldPrice && newPrice && oldPrice !== newPrice) {
                    this.$refs.changePriceConfirmModal.open(index);
                }
            },

            /**
             * Confirm price changed
             *
             * @param args
             */
            changePriceConfirm(args) {
                if (args.status) {
                    this.useNewPrice = true;
                }
                this.$set(this.model.items, args.index, {
                    ...this.model.items[args.index],
                    change_old_price: !!args.status,
                });
            },

        },

    };

</script>
