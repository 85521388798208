<template>
    <div class="row invoice-row">
        <div class="col-12 invoice-subtitle-bg">
            <h3 class="invoice-subtitle">
                {{ title }}
            </h3>
        </div>
        <div
            v-if="item"
            class="col-12"
        >
            <div class="row invoice-block-row">
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-2">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.name') }}</span>
                        <span
                            v-if="item.name || item.organization_name"
                            class="invoice-block-value"
                        >
                            {{ item.name ? item.name : item.organization_name }}
                        </span>
                        <span
                            v-else
                            class="invoice-block-value"
                        >{{ `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-4">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.eco_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.economy_code | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                v-if="item.economy_code"
                                :number="item.economy_code"
                                wrapper-class="invoice-block-value-digit"
                            />
                            <span v-else>{{ `-` }}</span>
                        </span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.reg_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.registered_no | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                v-if="item.registered_no"
                                :number="item.registered_no"
                                wrapper-class="invoice-block-value-digit"
                            />
                            <span v-else>{{ `-` }}</span>
                        </span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.national_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.national_id | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                v-if="item.national_id"
                                :number="item.national_id"
                                wrapper-class="invoice-block-value-digit"
                            />
                            <span v-else>{{ `-` }}</span>
                        </span>
                    </p>
                </div>
                <div
                    v-if="item.division"
                    class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-2"
                >
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.state') }}</span>
                        <span class="invoice-block-value">{{ item.division && item.division.province ? item.division.province : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-4">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.county') }}</span>
                        <span class="invoice-block-value">{{ item.division && item.division.county ? item.division.county : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.city') }}</span>
                        <span class="invoice-block-value">{{ item.division && item.division.city ? item.division.city : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.zipcode') }}</span>
                        <span class="invoice-block-value in-print">{{ item.postal_code | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                v-if="item.postal_code"
                                :number="item.postal_code"
                                wrapper-class="invoice-block-value-digit"
                            />
                            <span v-else>{{ `-` }}</span>
                        </span>
                    </p>
                </div>
                <div class="col-print-3 col-12 col-sm-12 col-md-6 col-lg-9">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.address') }}</span>
                        <span class="invoice-block-value">{{ item.address ? item.address : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-12 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('invoice.phone') }}</span>
                        <span class="invoice-block-value">
                            {{ (item.phone ? item.phone : item.tel ? item.tel : `-`) | digits }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import YSplitNumber from '@/modules/icib/components/SplitNumbers';

    export default {
        name: 'YInvoiceInfo',

        components: {
            YSplitNumber,
        },

        props: {
            title: {
                type: String,
            },
            item: {
                type: [Array, Object],
            },
        },
    };

</script>
