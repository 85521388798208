<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            :color="color"
            :icon="icon"
        >
            <p>{{ $t('cart.nodes.coupon.unused', [node.data.model.code.slug, node.data.model.code.rulebook.title]) }}</p>
            <div class="row">
                <div class="col-sm-12">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.nodes.coupon.coupon_discount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="generateAmountObject(node.data.model.applied_discount, node.data.currency.title)" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.nodes.coupon.old_payable_amount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="generateAmountObject(node.data.old_payable_amount, node.data.currency.title)" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.nodes.coupon.new_payable_amount') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="generateAmountObject(node.data.payable_amount, node.data.currency.title)" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.nodes.coupon.old_payable_status') }}
                        </div>
                        <div class="value">
                            <span class="label">
                                {{ node.data.old_payment_status }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('cart.nodes.coupon.new_payable_status') }}
                        </div>
                        <div class="value">
                            <span class="label">
                                {{ node.data.payment_status }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {

        name: 'CouponUnusedNode',

        components: {
            YFormAmount,
        },

        mixins: [TimelineNode],

        methods: {
            /**
             * Return FormAmount's element object
             *
             * @param value
             * @param postfix
             */
            generateAmountObject(value, postfix) {
                return {
                    value,
                    postfix,
                };
            },
        },

    };

</script>
