<template>
    <div class="invoice-wrapper">
        <y-loading
            :active="$wait.is('loading-invoice')"
            height="500px"
        >
            <main>
                <y-panel class="invoice-wrapper">
                    <div
                        id="invoice"
                        ref="invoice"
                        class="invoice"
                    >
                        <!-- Invoice Title -->
                        <div class="row align-items-center invoice-row invoice-header">
                            <div class="col-print-3 resized col-8 col-md-9">
                                <h3 class="invoice-title">
                                    {{ $t('invoice.title') }}
                                </h3>
                            </div>
                            <div class="col-print-1 resized col-4 col-md-2">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="invoice-block">
                                            <span class="invoice-block-key">{{ $t('invoice.number') }}</span>
                                            <span class="invoice-block-value">{{ invoice.idx | digits }}</span>
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <p class="invoice-block">
                                            <span class="invoice-block-key">{{ $t('invoice.date') }}</span>
                                            <span class="invoice-block-value">{{ invoice.effected_at | date('YYYY/MM/DD', $i18n.locale) | digits }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Seller Details -->
                        <y-invoice-info
                            v-if="seller"
                            :title="$t('invoice.seller_title')"
                            :item="seller"
                        />

                        <!-- Buyer Details -->
                        <y-invoice-info
                            v-if="buyer"
                            :title="$t('invoice.buyer_title')"
                            :item="buyer"
                        />

                        <!-- Product Details -->
                        <div class="row invoice-row">
                            <div class="col-12 invoice-subtitle-bg">
                                <h3 class="invoice-subtitle">
                                    {{ $t('invoice.product_title') }}
                                </h3>
                            </div>
                            <div class="col-12 invoice-table">
                                <div class="invoice-table-container">
                                    <div class="table-wrapper">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        v-for="column in product_table_title"
                                                        :key="column.id"
                                                        class="invoice-block-key no-dots"
                                                    >
                                                        {{ column.title }}
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody v-if="product && product.length">
                                                <tr
                                                    v-for="(row, index) in product"
                                                    :key="index"
                                                >
                                                    <td class="invoice-block-value">
                                                        {{ (index + 1) | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.code | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.title | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.count | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        {{ row.unit | digits }}
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.single_original_amount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.original_amount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.discount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.tax_amount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: row.payable_amount }"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" />
                                                    <!-- TODO: Add currency to the label when back is ready -->
                                                    <td class="invoice-block-key no-dots">
                                                        {{ `${$t('invoice.product.shipping')}` }}
                                                    </td>
                                                    <td colspan="3" />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: invoice.delivery_price }"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="10">
                                                        {{ $t('invoice.empty_product') }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td colspan="2" />
                                                    <td class="invoice-block-key no-dots">
                                                        {{ $t('invoice.product.total') }}
                                                    </td>
                                                    <td colspan="3" />
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: invoice.original_amount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: invoice.total_discount }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: taxSum() }"
                                                        />
                                                    </td>
                                                    <td class="invoice-block-value">
                                                        <y-form-amount
                                                            class="mb0"
                                                            :element="{ value: invoice.payable_amount }"
                                                        />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Signs and Description -->
                        <div class="row invoice-row">
                            <div class="col-print-2 col-6">
                                <p class="invoice-block is-sign">
                                    <span class="invoice-block-key no-dots">{{ $t('invoice.seller_sign') }}</span>
                                </p>
                            </div>
                            <div class="col-print-2 col-6">
                                <p class="invoice-block is-sign">
                                    <span class="invoice-block-key no-dots">{{ $t('invoice.buyer_sign') }}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice-row is-actions">
                        <div class="col-12 invoice-button-row">
                            <y-button
                                v-if="isEditMode"
                                class="invoice-button"
                                color="red"
                                @click="isEditMode = false"
                            >
                                {{ $t('invoice.cancel_edit') }}
                            </y-button>
                            <y-button
                                v-if="isEditMode"
                                class="invoice-button"
                                color="green"
                                @click="editDiscount"
                            >
                                {{ $t('invoice.save_discount') }}
                            </y-button>

                            <y-button
                                v-show="!isEditMode"
                                v-if="isEditable"
                                class="invoice-button"
                                color="blue"
                                @click="isEditMode = true"
                            >
                                {{ $t('invoice.edit_discount') }}
                            </y-button>

                            <y-button
                                class="invoice-button"
                                color="yellow"
                                @click="printSection('invoice')"
                            >
                                {{ $t('invoice.print') }}
                            </y-button>
                        </div>
                    </div>
                </y-panel>
            </main>
        </y-loading>
    </div>
</template>

<script>
    import YFormAmount from '@deps/form/elements/Amount';
    import Num2persian from '@/modules/cart/mixins/digitsToPersianWords';
    import YInvoiceInfo from '@/modules/cart/components/invoice/InvoiceInfo';

    export default {
        name: 'CartInvoiceSingle',

        components: {
            YFormAmount,
            YInvoiceInfo,
        },

        mixins: [
            Num2persian,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('invoice.view_invoice'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                id        : this.$route.params.id,
                isEditMode: false,
                isEditable: null,
                discount  : null,

                data   : null,
                seller : [],
                buyer  : [],
                invoice: [],

                product            : null,
                product_table_title: [
                    {
                        title: this.$t('invoice.product.row'),
                        id   : 'row_no',
                    },
                    {
                        title: this.$t('invoice.product.code'),
                        id   : 'code',
                    },
                    {
                        title: this.$t('invoice.product.name'),
                        id   : 'name',
                    },
                    {
                        title: this.$t('invoice.product.count'),
                        id   : 'number',
                    },
                    {
                        title: this.$t('invoice.product.unit'),
                        id   : 'unit',
                    },
                    {
                        title: this.$t('invoice.product.unit_price'),
                        id   : 'unit_price',
                    },
                    {
                        title: this.$t('invoice.product.total_fee'),
                        id   : 'total_fee',
                    },
                    {
                        title: this.$t('invoice.product.discount'),
                        id   : 'discount',
                    },
                    {
                        title: this.$t('invoice.product.tax'),
                        id   : 'tax',
                    },
                    {
                        title: this.$t('invoice.product.total'),
                        id   : 'total',
                    },

                ],
            };
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.fetch();
        },

        methods: {

            /**
             * fetch data
             */
            fetch() {
                this.$wait.start('loading-invoice');
                const { id } = this.$route.params;
                // Get Items
                this.$services.Cart.invoiceFetch({ id }).then((response) => {
                    this.data = response.data.results;
                    this.seller = response.data.results.seller_information;
                    this.buyer = response.data.results.buyer_information;
                    this.product = response.data.results.products_information;
                    this.invoice = response.data.results.invoice_information;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-invoice');
                });
            },

            /**
             * Print Section
             */
            printSection() {
                const body = document.getElementById('invoice').innerHTML;
                const WinPrint = window.open('', '_blank', 'top=0,right=0,width=1600,height=794,toolbar=0,scrollbars=0,status=0,fullscreen=1');
                WinPrint.document.write(`<!DOCTYPE html>
                <html dir="rtl" lang="fa">
                  <head>
                    <style type="text/css">${this.$config('print.typo')}</style>
                    <style type="text/css">${this.$config('print.col')}</style>
                    <style type="text/css">${this.$config('print.table')}</style>
                    <style type="text/css">${this.$config('print.invoice')}</style>
                    <title>${document.title}</title>
                  </head>
                  <body class="print">
                    ${body}
                  </body>
                </html>`);
                setTimeout(() => {
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            },

            /**
             * Sum of the item
             */
            taxSum() {
                let sum = 0;
                if (this.product) {
                    this.product.forEach((i) => {
                        sum += i.tax_amount;
                    });
                }
                return sum;
            },
        },
    };

</script>
