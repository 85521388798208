<template>
    <y-timeline-node
        v-bind="nodeBind"
        color="blue"
        icon="md-pulse"
    >
        <p>
            {{ data.title ? $t('cart.nodes.shipping_used.text', [digitsHelper(data.title)]) : '' }}
            {{ data.freight ? $t('cart.nodes.shipping_used.fee', [digitsHelper(addFloutingPoint(data.freight))]) : '' }}
            {{ data.slot ? $t('cart.nodes.shipping_used.for', [digitsHelper(data.slot)]) : '' }}
            {{ $t('cart.nodes.shipping_used.verb') }}
        </p>
    </y-timeline-node>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'ShippingUsedNode',

        mixins: [TimelineNode],

        methods: {
            /**
             * Convert to locale format
             *
             * @param {string | number} value
             * @returns {string}
             */
            addFloutingPoint(value) {
                if (!value) {
                    return ' - ';
                }
                return value.toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            },

            /**
             * Digit helper for using in inline condition
             * 
             * @param {number} value - Number
             */
            digitsHelper(value) {
                return digits(value, this.$i18n.locale);
            },
        },
    };
</script>
