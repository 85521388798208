<template>
    <y-modal
        ref="Modal"
        class="inventory-modal"
        forced
    >
        <div slot="header">
            {{ title }}
        </div>

        <div slot="body">
            <y-loading
                :active="$wait.is('loading-form')"
                height="150px"
            >
                <form
                    v-if="settings"
                    id="inentory-process-form"
                    @submit.prevent="submit"
                >
                    <y-form-field
                        v-model="model.status"
                        type="select"
                        :options="settings.statuses"
                        name="type"
                        label-field="label"
                        value-field="value"
                    />

                    <y-form-field
                        v-if="model.status === 0 || model.status === 1"
                        v-model="model.damaged"
                        type="checkbox"
                        name="damaged"
                    />

                    <y-form-field
                        v-if="(model.status === 0 || model.status === 1) && model.damaged"
                        v-model="model.damaged_reason"
                        type="textarea"
                        name="damaged_reason"
                    />

                    <y-form-field
                        v-if="model.status === 2"
                        v-model="model.rejected_reason"
                        type="textarea"
                        name="reason"
                        :validation="model.status === 2 ? 'required' : ''"
                    />

                    <div
                        v-if="model.status === 0 || model.status === 1"
                        class="row"
                    >
                        <div class="col-sm-6">
                            <y-form-field
                                v-model="model.confirmed_count"
                                type="number"
                                format="separated"
                                name="count"
                                validation="required"
                            />
                        </div>
                        <div class="col-sm-6">
                            <y-form-field
                                v-if="model.damaged"
                                v-model="model.recycled_amount"
                                type="number"
                                format="separated"
                                name="recycled_amount"
                            />
                        </div>
                    </div>

                    <template v-if="model.confirmed_count && settings.ware_unique_codes && settings.ware_unique_codes.length > 0">
                        <y-item-inventory
                            v-if="model.status === 0 || model.status === 1"
                            v-model="model.returned_codes"
                            :title="$t('cart.claim.inventory_process.returned_code')"
                            :all-codes="settings.order_uniques_code"
                            :count="model.confirmed_count"
                        />

                        <y-item-inventory
                            v-if="model.status === 1"
                            v-model="model.replaced_codes"
                            :title="$t('cart.claim.inventory_process.replaced_code')"
                            :all-codes="settings.ware_unique_codes"
                            :count="model.confirmed_count"
                            :value="model.replaced_codes"
                            :returned-codes="model.returned_codes"
                        />
                    </template>
                </form>

                <y-alert
                    v-if="checkAlert"
                    color="red"
                    :message="alertMessage"
                />
            </y-loading>
        </div>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="green"
                    :disabled="checkAlert"
                    loading-on="inentory-process-form"
                    @click.prevent.native="submit"
                >
                    {{ $t('cart.actions.change_status') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { digits } from '@nodes/helpers/number';
    import FormMixin from '@/mixins/Form';
    import { YModal, YAlert } from '@deps';
    import YFormField from '@deps/form/FormField';
    import YItemInventory from '@/modules/cart/components/claims/ItemInventory';
    
    export default {
        name: 'CartClaimInventoryProcess',

        components: {
            YModal,
            YAlert,
            YFormField,
            YItemInventory,
        },

        mixins: [FormMixin],

        props: {
            claimId: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : {},
                settings: null,
                id      : null,
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.$t('cart.claim.inventory_process.title');
            },

            /**
             * Check if alert should be shown
             */
            checkAlert() {
                let alert = false;
                if (this.model.confirmed_count > this.model.claimed_count
                    || (this.model.returned_codes && (this.model.returned_codes.length > this.model.confirmed_count))
                    || (this.model.replaced_codes && (this.model.replaced_codes.length > this.model.returned_codes.length))) {
                    alert = true;
                }
                return alert;
            },

            /**
             * Appropriate alert message
             */
            alertMessage() {
                let trans = '';
                if (this.model.confirmed_count > this.model.claimed_count) {
                    trans = this.$t('cart.claim.inventory_process.alert.count', [this.claimedCount]);
                } else if (this.model.returned_codes && (this.model.returned_codes.length > this.model.confirmed_count)) {
                    trans = this.$t('cart.claim.inventory_process.alert.returned');
                } else if (this.model.replaced_codes && (this.model.replaced_codes.length > this.model.returned_codes.length)) {
                    trans = this.$t('cart.claim.inventory_process.alert.replaced');
                }
                return trans;
            },

            /**
             * Return digits of count
             */
            claimedCount() {
                return digits(this.model.claimed_count, this.$i18n.locale);
            },
        },

        methods: {
            /**
             * Fetch inventory details
             */
            fetch() {
                this.$wait.start('loading-form');
                const params = {
                    claim_id: this.claimId,
                    item_id : this.id,
                };
                this.$services.Cart.claimItemFetch(params).then((response) => {
                    this.model = response.data.results;
                    this.settings = response.data.metadata;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-form'));
            },
            
            /**
             * Open modal
             *
             * @param id
             */
            open(id) {
                this.$set(this, 'id', id);
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.model = {};
            },

            /**
             * Submit Form
             */
            submit() {
                this.$wait.start('inentory-process-form');
                const params = {
                    claim_id: this.claimId,
                    item_id : this.id,
                    ...this.model,
                };
                this.$services.Cart.claimItemProcessing(params).then(() => {
                    this.$emit('done');
                    this.$toast.success(this.$t('cart.claim.message.change_status.success'));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('inentory-process-form');
                });
            },
        },
    };
</script>
