<template>
    <y-timeline-node
        v-bind="nodeBind"
        color="blue"
        icon="md-pulse"
    >
        <p>
            {{ $t('cart.nodes.payment_paid.text', [
                data.model.type_label,
                (Number(data.model.amount).toLocaleString()),
                data.model.currency_title, data.model.path_title
            ]) | digits }}
        </p>
    </y-timeline-node>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'PaymentPaidNode',

        mixins: [TimelineNode],
    };
</script>
