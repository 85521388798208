<template>
    <div>
        <y-page-head
            :title="title"
            class="cart-timeline"
            icon="md-cart-arrow-right"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    key="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    v-if="buttons && buttons.edit"
                    key="edit-link-button"
                    tag="router-link"
                    :to="editCart"
                >
                    {{ $t('cart.actions.edit') }}
                </y-button>

                <y-dropdown v-if="statusOptions && statusOptions.length > 0">
                    <template slot="toggle">
                        <y-button>{{ $t('cart.actions.change_status') }}</y-button>
                    </template>

                    <span
                        v-for="(option, index) in statusOptions"
                        :key="index"
                        class="cart-change-status"
                        @click="changeStatus(option)"
                    >
                        {{ option.label }}
                    </span>
                </y-dropdown>

                <y-dropdown
                    v-if="showActionButtons"
                    class="action-buttons"
                >
                    <template slot="toggle">
                        <y-button>{{ $t('button.action') }}</y-button>
                    </template>

                    <y-button
                        v-if="buttons && buttons.inventory_processing"
                        key="inventory_processing-button"
                        @click.prevent="$refs.inventoryProcessingModal.open()"
                    >
                        {{ $t('cart.actions.inventory') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.payment_processing"
                        @click.prevent.native="$refs.paymentManagementModal.open(id)"
                    >
                        {{ $t('cart.actions.payment') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.invoice_generate"
                        @click.prevent.native="$refs.invoiceConfirmModal.open(id)"
                    >
                        {{ $t('cart.actions.invoice.generate') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.invoice_regenerate"
                        @click.prevent.native="$refs.invoiceConfirmModal.open(id)"
                    >
                        {{ $t('cart.actions.invoice.regenerate') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.invoice_view"
                        tag="router-link"
                        :to="invoiceViewRoute()"
                        target="_blank"
                    >
                        {{ $t('cart.actions.invoice.view_invoice') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.close"
                        key="close-order-button"
                        :loading="$wait.is(closeLoading)"
                        aria-label="Close"
                        @click.prevent="onCloseCart"
                    >
                        {{ $t('cart.actions.order_close') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.reopen"
                        key="reopne-order-button"
                        :loading="$wait.is(reopenLoading)"
                        aria-label="Reopen"
                        @click.prevent="onReopenCart"
                    >
                        {{ $t('cart.actions.order_reopen') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.claim"
                        key="new-claim"
                        tag="router-link"
                        :to="isClaim ? editClaim : newClaim"
                    >
                        {{ isClaim ? $t('cart.claim.edit') : $t('cart.claim.create') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.convert"
                        key="preorder-convert"
                        :loading="$wait.is(convertPreOrderLoading)"
                        @click.prevent="onConvertToOrder"
                    >
                        {{ $t('cart.actions.convert_to_order') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.coupon_processing"
                        key="coupon-processing"
                        @click.prevent="onOpenCouponProcessing"
                    >
                        {{ $t('cart.actions.coupon_processing') }}
                    </y-button>

                    <y-button
                        v-if="buttons && buttons.shipping_processing"
                        key="shipping-processing"
                        @click.prevent.native="$refs.shippingProcessingModal.open(id)"
                    >
                        {{ $t('cart.actions.shipping_processing') }}
                    </y-button>
                </y-dropdown>
            </template>
        </y-page-head>

        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 300px )"
        >
            <main
                v-if="timeline"
                id="cart-details"
            >
                <div class="row">
                    <div class="col-sm-8">
                        <y-details-timeline
                            :cart="timeline"
                            :filters="filters"
                        />
                    </div>

                    <div class="col-sm-4">
                        <y-details-panel :cart="details" />

                        <y-comment-panel @done="reFetch" />
                    </div>
                </div>

                <y-change-order-status-modal
                    ref="changeOrderStatusModal"
                    @done="reFetch"
                />

                <y-inventory-processing-modal ref="inventoryProcessingModal" />

                <y-payment-management-modal
                    ref="paymentManagementModal"
                    @done="reFetch"
                />

                <y-invoice-confirm-modal
                    ref="invoiceConfirmModal"
                    @done="reFetch"
                />

                <y-order-promp-modal ref="promptModal" />

                <y-coupon-processing-modal
                    ref="couponProcessingModal"
                    @done="reFetch"
                />

                <y-shipping-processing-modal
                    ref="shippingProcessingModal"
                    @done="reFetch"
                />
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import { YDropdown } from '@deps';

    import YDetailsPanel from '@/modules/cart/components/details/DetailsPanel';
    import YDetailsTimeline from '@/modules/cart/components/details/DetailsTimeline';
    import YCommentPanel from '@/modules/cart/components/details/CommentPanel';
    import YChangeOrderStatusModal from '@/modules/cart/components/details/ChangeOrderStatusModal';
    import YInventoryProcessingModal from '@/modules/cart/components/details/InventoryProcessingModal';
    import YPaymentManagementModal from '@/modules/cart/components/details/PaymentManagementModal';
    import YInvoiceConfirmModal from '@/modules/cart/components/details/InvoiceConfirmModal';
    import YOrderPrompModal from '@/modules/cart/components/details/OrderPromptModal';
    import YCouponProcessingModal from '@/modules/cart/components/details/CouponProcessingModal';
    import YShippingProcessingModal from '@/modules/cart/components/details/ShippingProcessingModal';

    export default {

        name: 'CartDetails',

        components: {
            YDropdown,
            YDetailsPanel,
            YDetailsTimeline,
            YCommentPanel,
            YChangeOrderStatusModal,
            YInventoryProcessingModal,
            YPaymentManagementModal,
            YInvoiceConfirmModal,
            YOrderPrompModal,
            YCouponProcessingModal,
            YShippingProcessingModal,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.get(this.timeline, 'title', ''),
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('cart', this.$t('breadcrumbs.cart.cart')),
                this.$bc(this.$t('cart.timeline.details', [this.title])),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id           : this.$route.params.id,
                timeline     : null,
                buttons      : null,
                status       : null,
                statusOptions: [],
                filters      : [],
                details      : [],
                closeLoading : 'closing-order',
                reopenLoading: 'reopening-order',

                convertPreOrderLoading: 'convert-preorder-to-order',
            };
        },

        computed: {

            /**
             * Page title
             */
            title() {
                return this.isPreOrder ? this.$t('cart.pre.details') : this.$t('cart.order.details');
            },

            /**
             * Check if the order has claim
             */
            isClaim() {
                return this.timeline && this.timeline.claim_id;
            },

            /**
             * Edit Link
             */
            editCart() {
                return {
                    name  : this.timeline.is_pre_order ? 'cart-pre-orders-edit' : 'cart-orders-edit',
                    params: {
                        id: this.$route.params.id,
                    },
                };
            },

            /**
             * New Claim Link
             */
            newClaim() {
                return {
                    name  : 'cart-claims-create',
                    params: {
                        order: this.$route.params.id,
                    },
                };
            },

            /**
             * Edit Claim Link
             */
            editClaim() {
                return {
                    name  : 'cart-claims-edit',
                    params: {
                        id: this.timeline.claim_id,
                    },
                };
            },

            /**
             * Whether to Show action buttons dropdown
             */
            showActionButtons() {
                return this.buttons && (
                    this.buttons.inventory_processing
                    || this.buttons.payment_processing
                    || this.buttons.close
                    || this.buttons.reopen
                    || this.buttons.claim
                    || this.buttons.convert
                );
            },

            /**
             * Whether its order or pre-order
             */
            isPreOrder() {
                return this.get(this.timeline, 'is_pre_order', false);
            },
        },

        methods: {

            /**
             * Fetch cart details
             */
            fetch() {
                const params = {
                    id: this.$route.params.id,
                };
                const timeline = this.$services.Cart.orderTimeline(params);
                const details = this.$services.Cart.orderDetails(params);

                return Promise.all([timeline, details]).then((responses) => {
                    this.timeline = responses[0].data.results;
                    this.buttons = responses[0].data.metadata.buttons;
                    this.filters = responses[0].data.metadata.filters;
                    this.statusOptions = this.get(responses[0].data.metadata, 'applicable_statuses', []);

                    this.details = responses[1].data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal and change status
             *
             * @param status
             */
            changeStatus(status) {
                const params = {
                    id    : this.details.id,
                    status: status.value,
                };
                this.$services.Cart.orderStatusFetch(params).then((result) => {
                    const { form } = result.data.metadata;
                    const { metadata } = result.data;
                    const model = this.statusFormAdapter(form);

                    this.$refs.changeOrderStatusModal.open({
                        metadata,
                        form,
                        model,
                        newStatus: status,
                        oldStatus: this.details,
                    });
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Read status form model and generate data model according to the respecting form
             *
             * @param formModel
             */
            statusFormAdapter(formModel) {
                const dataModel = {};
                if (Array.isArray(formModel)) {
                    formModel.forEach((formElement) => {
                        dataModel[formElement.name] = formElement.value;
                    });
                }

                return dataModel;
            },

            /**
             * Check the Buttons of the toolbar
             *
             * @param key
             */
            hasButton(key) {
                let hasKey = false;
                if (this.buttons) {
                    Object.values(this.buttons).forEach((btn) => {
                        if (btn === key) {
                            hasKey = true;
                        }
                    });
                }
                return hasKey;
            },

            /**
             * open prompt modal to verify closing the cart
             */
            onCloseCart() {
                this.$refs.promptModal.open({
                    promptCaption: this.$t('cart.prompt.close_caption'),
                    promptAction : this.closeCart,
                    promptTitle  : this.$t('cart.actions.order_close'),
                    promptLoading: this.closeLoading,
                });
            },

            /**
             * Close cart and refresh cart with new state
             */
            closeCart() {
                this.$wait.start(this.closeLoading);
                const params = {
                    id: this.details.id,
                };
                this.$services.Cart.orderClose(params).then(() => {
                    this.$toast.success(this.$t('cart.order.message.close.success'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$refs.promptModal.close();
                    this.$wait.end(this.closeLoading);
                    this.reFetch();
                });
            },


            /**
             * open prompt modal to verify reopening the cart
             */
            onReopenCart() {
                this.$refs.promptModal.open({
                    promptCaption: this.$t('cart.prompt.open_caption'),
                    promptAction : this.reopenCart,
                    promptTitle  : this.$t('cart.actions.order_reopen'),
                    promptLoading: this.reopenLoading,
                });
            },
            /**
             * Reopen cart and refresh cart with new state
             */
            reopenCart() {
                this.$wait.start(this.reopenLoading);
                const params = {
                    id: this.details.id,
                };
                this.$services.Cart.orderReopen(params).then(() => {
                    this.$toast.success(this.$t('cart.order.message.reopen.success'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$refs.promptModal.close();
                    this.$wait.end(this.reopenLoading);
                    this.reFetch();
                });
            },

            /**
             * Create route to the invoice grid of this order/pre-order
             */
            invoiceViewRoute() {
                return {
                    name : 'cart-invoices-list',
                    query: {
                        order_id: this.$route.params.id,
                    },
                };
            },

            /**
             * Open confirmation modal before converting preorder
             */
            onConvertToOrder() {
                this.$refs.promptModal.open({
                    promptCaption: this.$t('cart.prompt.convert_caption'),
                    promptAction : this.convertToOrder,
                    promptTitle  : this.$t('cart.prompt.convert_title'),
                    promptLoading: this.convertPreOrderLoading,
                });
            },

            /**
             * Convert preorder to order
             */
            convertToOrder() {
                this.$wait.start(this.convertPreOrderLoading);
                const params = {
                    id: this.details.id,
                };
                this.$services.Cart.preOrderConvert(params).then(() => {
                    this.$toast.success(this.$t('cart.message.convert.success'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$refs.promptModal.close();
                    this.$wait.end(this.convertPreOrderLoading);
                    this.reFetch();
                });
            },

            /**
             * Open coupon processing modal
             */
            onOpenCouponProcessing() {
                this.$refs.couponProcessingModal.open();
            },
        },

    };

</script>
