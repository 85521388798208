<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="green"
            icon="md-plus"
            has-hood
        >
            <template #modal="data">
                <node-modal :content="data.content" />
            </template>
        </y-timeline-node>
    </div>
</template>

<script>

    import TimelineNode from '@/mixins/TimelineNode';
    import NodeModal from './NodeModal';

    export default {

        name: 'PreOrderCreatedNode',
        
        components: {
            NodeModal,
        },

        mixins: [TimelineNode],
    };

</script>
